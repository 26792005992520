
export const nl2br = (note: string) => {
    return note.split("\n").map(function (item, idx) {
        return (
            <span key={idx}>
                {item}
                <br />
            </span>
        )
    })
}
