export enum InterfaceSettingKey {
  IS_HIGH_CONTRAST = "interface.settings.is_high_contrast",
  FONT_SIZE = "interface.settings.font_size",
  IS_INCREASED_LINE_HEIGHT = "interface.settings.is_increased_line_height",
  IS_INCREASED_WORD_SPACING = "interface.settings.is_increased_word_spacing"
}

export const InterfaceSettings = {
  clearAll: (): void => {
    localStorage.removeItem(InterfaceSettingKey.IS_HIGH_CONTRAST);
    localStorage.removeItem(InterfaceSettingKey.FONT_SIZE);
    localStorage.removeItem(InterfaceSettingKey.IS_INCREASED_WORD_SPACING);
    localStorage.removeItem(InterfaceSettingKey.IS_INCREASED_LINE_HEIGHT);
  }
}
