import React from "react";
import { LogoutService } from "services/logout-service"
import { $t, t_key } from "../plugins/i18n";
import { SessionInfo } from "models/session_info";

export type MainPageProps = {
    session: SessionInfo
}

const ForceLogoutPage = (props: MainPageProps) => {
    const logout = async () => {
        let urlParams = new URLSearchParams(location.search);
        let returnUrl = document.referrer;
        if (urlParams.has("returnUrl")) {
            returnUrl = urlParams.get("returnUrl")!;
        } else if (urlParams.has("service")) {
          returnUrl = urlParams.get("service")!;
        }

        var resp = await LogoutService.forceLogout(returnUrl);
        if (resp.redirectUrl && resp.redirectUrl !== window.location.href) {
            window.location.href = resp.redirectUrl;
        } else {
            history.back();
        }
    }

    React.useEffect(() => {
        logout();
    });

    return (
        <></>
    )
}

export default ForceLogoutPage;