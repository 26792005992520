import { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

export type ErrorViewProps = {
    message: string,
    description: string,
    buttons?: ReactNode,
}

const ErrorView = (props: ErrorViewProps) => {
    return (<Row>
        <Col xs={12} md={6} lg={7} xl={8} className="col1">
            <h1 tabIndex={0}>{props.message}</h1>
            <p tabIndex={0}>{props.description}</p>
            {props.buttons ? <p>{props.buttons}</p> : null}
        </Col>
        <Col xs={12} md={6} lg={5} xl={4} className="col2">
            <div className="d-grid gap-4">
            </div>
        </Col>
    </Row>);
}
ErrorView.displayName = "ErrorView"; //for chrome to show name instead of anonymous.

export default ErrorView;
