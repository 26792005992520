import { Container, Row, Col, Button } from "react-bootstrap";
import { SessionInfo } from "../models/session_info";
import { $t, t_key } from "../plugins/i18n";
import { useNavigate } from "react-router";


export type LoginFailedPageProps = {
    session: SessionInfo,
    enableRetry: boolean
}

const LoginFailedPage = (props: LoginFailedPageProps) => {
    const navigate = useNavigate();

    const retry = () => {
        navigate(`/?app_id=${props.session.application?.appId}`);
	}

    return (
        <Container className="content-area">
            <Row>
                <Col xs={12} className="col1">
                    <h1 tabIndex={0}>{$t(t_key.login_failed_page.message)}</h1>
                    {props.enableRetry ? <Button variant='primary' onClick={() => retry()}>{$t(t_key.buttons.retry)}</Button> : null}
                </Col>
            </Row>
        </Container>
    )
}

export default LoginFailedPage;
