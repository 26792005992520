
const SmartId = () => {
    return (<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_391_2839)">
    <path d="M32.0232 18.885C33.1 18.885 33.973 18.012 33.973 16.9351C33.973 15.8582 33.1 14.9852 32.0232 14.9852C30.9463 14.9852 30.0733 15.8582 30.0733 16.9351C30.0733 18.012 30.9463 18.885 32.0232 18.885Z" fill="#090688"/>
    <path d="M42.0695 49.0639C41.7437 49.0639 41.4263 48.9612 41.1624 48.7702C40.8984 48.5793 40.7015 48.3099 40.5997 48.0005C40.4978 47.6911 40.4962 47.3574 40.595 47.047C40.6939 46.7366 40.8882 46.4654 41.1502 46.2719C45.2115 43.29 48.2269 39.1013 49.7658 34.3036C51.3048 29.506 51.2886 24.3448 49.7197 19.5569C48.1507 14.769 45.1092 10.5992 41.0293 7.64285C36.9495 4.68647 32.0398 3.09467 27.0014 3.0947C25.8379 3.09556 24.6758 3.17952 23.5242 3.34593V37.1534C23.5242 37.3566 23.4842 37.5578 23.4064 37.7455C23.3286 37.9333 23.2147 38.1039 23.071 38.2475C22.9273 38.3912 22.7567 38.5052 22.569 38.5829C22.3813 38.6607 22.1801 38.7007 21.9769 38.7007C21.7737 38.7007 21.5724 38.6607 21.3847 38.5829C21.197 38.5052 21.0264 38.3912 20.8827 38.2475C20.739 38.1039 20.6251 37.9333 20.5473 37.7455C20.4695 37.5578 20.4295 37.3566 20.4295 37.1534V2.03841C20.4292 1.68108 20.5528 1.33466 20.7792 1.05823C21.0057 0.781809 21.321 0.592469 21.6714 0.522455C27.7027 -0.694999 33.9689 0.183755 39.4327 3.01326C44.8965 5.84276 49.2302 10.4533 51.7163 16.0817C54.2025 21.71 54.692 28.0186 53.1038 33.9631C51.5156 39.9076 47.945 45.1314 42.983 48.7699C42.7173 48.9621 42.3974 49.065 42.0695 49.0639Z" fill="#090688"/>
    <path d="M27.0014 54C21.3096 54.0048 15.7619 52.2102 11.1513 48.8727C6.54064 45.5353 3.10298 40.8258 1.3296 35.4173C-0.443783 30.0088 -0.462115 24.1781 1.27722 18.7585C3.01656 13.339 6.42455 8.60797 11.0141 5.24158C11.3452 5.01086 11.7533 4.91862 12.1515 4.98453C12.5496 5.05043 12.9062 5.26925 13.1453 5.59436C13.3844 5.91947 13.487 6.32513 13.4313 6.72481C13.3756 7.1245 13.1658 7.48659 12.8469 7.73388C8.78363 10.7139 5.76612 14.9019 4.2255 19.6996C2.68487 24.4972 2.69996 29.659 4.2686 34.4476C5.83725 39.2362 8.8792 43.4065 12.9598 46.3627C17.0405 49.319 21.9511 50.9099 26.99 50.9082C28.1527 50.9076 29.3138 50.8237 30.4644 50.657V28.4774C30.4644 28.067 30.6274 27.6735 30.9176 27.3833C31.2078 27.0931 31.6013 26.9301 32.0117 26.9301C32.4221 26.9301 32.8157 27.0931 33.1059 27.3833C33.396 27.6735 33.5591 28.067 33.5591 28.4774V51.9588C33.5594 52.3161 33.4358 52.6625 33.2094 52.9389C32.9829 53.2154 32.6676 53.4047 32.3172 53.4747C30.5668 53.8236 28.7863 53.9996 27.0014 54Z" fill="#090688"/>
    </g>
    <defs>
    <clipPath id="clip0_391_2839">
    <rect width="54" height="54" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    );
}

export default SmartId;