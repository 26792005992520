import { $t, t_key } from "../plugins/i18n";
import validator from "validator";

export type ValidationResponse = {
  isValid: boolean,
  message: string
}

//https://stackoverflow.com/a/201378
// note(kristen): this regex is probably wrong (because they are all wrong)
export const emailFormat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const validateEmail = (val: string) => {
  return validator.isEmail(val);
}

export const validatePhoneNumber = (phoneNumber: string): ValidationResponse => {
  if (/\s/.test(phoneNumber)) {
    return {
      isValid: false,
      message: $t(t_key.validation.invalid_phone_nr_spaces_not_allowed)
    } as ValidationResponse
  }

  if (!phoneNumber.startsWith("+")) {
    return {
      isValid: false,
      message: $t(t_key.validation.invalid_phone_missing_country_code)
    } as ValidationResponse
  }

  const phoneParts = phoneNumber.split("+");
  if (phoneParts.length < 2) {
    return {
      isValid: false,
      message: $t(t_key.validation.invalid_phone_number)
    } as ValidationResponse
  }

  const phoneWithoutPlus = phoneParts[1];
  const isValid = validator.isMobilePhone(phoneWithoutPlus);
  if (!isValid) {
    return {
      isValid: false,
      message: $t(t_key.validation.invalid_phone_number)
    } as ValidationResponse
  }

  return {
    isValid: true,
    message: ""
  } as ValidationResponse
}

export const showValidationErrors = (resp: any, setLocalErrors: Function) => {
    if (resp.errors === undefined && resp.error === undefined)
        return false;

    if (resp.error) {
        return true;
    }

    let tmp = {} as { [key: string]: any };
    let globalErrors = {} as { [key: string]: any };
    let hasGlobalError = false;
    let hasLocalErrors = false;
    for (let prop in resp.errors) {
        if (!Object.prototype.hasOwnProperty.call(resp.errors, prop)) continue;
        if (tmp[prop] === undefined) {
            globalErrors[prop] = resp.errors;
            hasGlobalError = true;
        }
        if (resp.errors[prop].length > 0) {
            tmp[prop] = resp.errors[prop][0];
            hasLocalErrors = true;
        }
    }

    if (hasGlobalError) {
        showError(globalErrors);
    }

    setLocalErrors(tmp);

    if (hasLocalErrors) {
        highlightFirstLocalError();
	}

    return true;
}

export const hideValidationErrors = (localErrors: any, setLocalErrors: Function) => {
  let tmp = { ...localErrors };
  for (let i in tmp) {
    if (tmp.hasOwnProperty(i)) {
      tmp[i] = null;
    }
  }
  setLocalErrors(tmp);
}

export const showError = (ex: any) => {
  console.error(ex);
}

export const showErrorModal = (resp: any, setErrorModal: Function) => {
    if (resp.errors && resp.errors.global) {
        setErrorModal({ open: true, message: $t(resp.errors.global) });
        return true;
    }
    if (resp.error) {
        setErrorModal({ open: true, message: $t(resp.error) });
        return true;
    }
    return false;
}

export const highlightFirstLocalError = () => {
    setTimeout(() => {
        const ctrl = document.querySelector('[aria-invalid="true"]') as HTMLElement | null;
        if (ctrl != null) {
            ctrl.focus();
		}
	}, 200);
}
