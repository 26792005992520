import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { SessionInfo } from "../models/session_info";
import i18n, { $t, t_key } from "../plugins/i18n";
import { nl2br } from "../plugins/string-helpers";
import { hideValidationErrors, showValidationErrors } from "../plugins/validation";
import { PasswordService } from "../services/password-service";
import ErrorView from "./error-view";
import { useParams } from "react-router-dom";
import SuccessView from "./success-view";
import { useNavigate } from "react-router";
import { TestResetPasswordResponse } from "../models/password-reminder-request";

type PasswordResetData = {
    password: string,
    repeat_password: string
};

export type PasswordResetPageProps = {
	session: SessionInfo
}

const PasswordResetPage = (props: PasswordResetPageProps) => {
    const [generalError, setGeneralError] = React.useState<boolean>(false);
    const [generalSuccess, setGeneralSuccess] = React.useState<boolean>(false);
    const [opening, setOpening] = React.useState<boolean>(true);
    const [appId, setAppId] = React.useState<string>("");
    const [formData, setFormData] = React.useState<PasswordResetData>({ password: "", repeat_password: "" });
    const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({ password: null, repeat_password: null, global: null });

    const navigate = useNavigate();
    const { access_hash } = useParams();

    const setField = (val: string, fld: string) => {
        let tmp = { ...formData } as { [key: string]: any };
        tmp[fld] = val;
        setFormData(tmp as PasswordResetData);
    }

    const cancelClick = () => { }
    const saveClick = () => {
        if (!access_hash)
            return;

        hideValidationErrors(localErrors, setLocalErrors);

        PasswordService.resetPassword(access_hash, formData)
            .then((resp) => {
                if (!showValidationErrors(resp, setLocalErrors)) {
                    setGeneralSuccess(true);
                }
            })
            .catch(() => {
                setGeneralError(true);
            });
    }
    const loginClick = () => {
        navigate(`/?app_id=${appId}`);
	}

    React.useEffect(() => {
        const loadPage = () => {
            if (!access_hash) {
                setGeneralError(true);
                return;
			}

            PasswordService.testResetPassword(access_hash)
                .then((resp) => setAppId((resp as TestResetPasswordResponse).app_id))
                .catch(() => setGeneralError(true))
                .finally(() => setOpening(false));
        };
        loadPage();
    }, []);

    if (opening)
        return null;

    const renderForm = () => {
        return (<Row>
            <Col xs={12} md={6} lg={7} xl={8} className="col1">
                <h1 tabIndex={0}>{$t(t_key.password_reset_view.title)}</h1>
                <p tabIndex={0}>{nl2br($t(t_key.password_reset_view.details))}</p>
            </Col>
            <Col xs={12} md={6} lg={5} xl={4} className="col2">
                <form>
                    <Row>
                        <Col xs={12}>
                            {localErrors.global ? <div className="local-error mt-3">{$t(localErrors.global)}</div> : null}
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>{$t(t_key.password_reset_view.password)} <span className="req-indic">*</span></Form.Label>
                                <Form.Control type="password" value={formData.password} onChange={e => setField(e.target.value, "password")} isInvalid={!!localErrors.password} />
                                {!!localErrors.password ? <Form.Control.Feedback type="invalid">{localErrors.password}</Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="repeat_password">
                                <Form.Label>{$t(t_key.password_reset_view.repeat_password)} <span className="req-indic">*</span></Form.Label>
                                <Form.Control type="password" value={formData.repeat_password} onChange={e => setField(e.target.value, "repeat_password")} isInvalid={!!localErrors.repeat_password} />
                                {!!localErrors.repeat_password ? <Form.Control.Feedback type="invalid">{localErrors.repeat_password}</Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="text-center mb-3 buttons-spacer">
                            <Button variant="secondary" onClick={() => cancelClick()}>{$t(t_key.buttons.cancel)}</Button>
                            <Button variant="primary" onClick={() => saveClick()}>{$t(t_key.buttons.save)}</Button>
                        </Col>
                    </Row>
                </form>
            </Col>
        </Row>);
	}

    return (
        <React.Fragment>
            <Container className="content-area">
                {
                    generalError ?
                        <ErrorView
                            message={$t(t_key.password_reset_view.failed)}
                            buttons={appId ? <Button variant="primary" onClick={() => loginClick()}>{$t(t_key.buttons.go_to_login)}</Button> : null}
                            description="" /> :
                        (generalSuccess ? <SuccessView message={$t(t_key.password_reset_view.password_changed)} description="" /> : renderForm())
                }
            </Container>
        </React.Fragment>
    );
}

export default PasswordResetPage;
