import React, { ForwardedRef, ReactNode, useRef } from 'react';
import AsyncSelect from 'react-select/async';

interface PropTypes {
    id?: string;
    placeholder?: string | ReactNode | undefined;
    isInvalid?: boolean;
    isValid?: boolean;
    isReadOnly?: boolean;
    isMulti?: true | undefined;
    onInputChange?(input: string): void;

    disabled?: boolean;
    defaultValue?: any;
    className?: string;
    options?: any;
    inputId?: string;
    select?: any;
    onChange?(value: any): void;
    value: any;
}

const AdsAutocomplete = React.forwardRef(
    (props: PropTypes, ref: ForwardedRef<PropTypes>) => {
        const {
            isInvalid,
            disabled,
            className,
            isValid,
            placeholder,
            value,
            isReadOnly,
        } = props;

        const timeoutRef = useRef<any>(null);

        const loadOptions = (
            inputValue: string,
            callback: (options: any[]) => void
        ) => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            if (!inputValue && !value) return;

            const searchValue =
                inputValue || (value && JSON.parse(value)?.pikkaadress);

            timeoutRef.current = setTimeout(() => {
                fetch(`https://inaadress.maaamet.ee/inaadress/gazetteer?appartment=1&address=${encodeURIComponent(searchValue)}`, {
                    method: "GET"
                })
                    .then(response => {
                        response.clone().json().then(data => callback(data.addresses));
                    }).catch(ex => console.error(ex));
            }, 500);
        };

        const parsedValue =
            value && typeof value === 'string' ? JSON.parse(value) : value;

        const getOptionLabel = (option: any) =>
            (option.kort_nr
                ? `${option.pikkaadress}-${option.kort_nr}`
                : option.pikkaadress
            )
                ?.split(', ')
                .reverse()
                .join(', ');

        return (
            <AsyncSelect
                id={props.id}
                // @ts-ignore
                ref={ref}
                isDisabled={disabled}
                inputId={props.inputId ?? "ads-address"}
                loadOptions={loadOptions}
                defaultInputValue={!parsedValue ? '' : undefined}
                classNamePrefix="styled"
                placeholder={placeholder ?? ""}
                className={`styled-container ${className ?? ''} ${isInvalid ? 'is-invalid' : ''
                    } ${isValid ? 'is-valid' : ''}`}
                {...props}
                value={parsedValue}
                getOptionLabel={getOptionLabel}
                isSearchable={!isReadOnly && !parsedValue}
                isClearable={!isReadOnly}
                menuIsOpen={isReadOnly || !!parsedValue ? false : undefined}
            />
        );
    }
);

export default AdsAutocomplete;
