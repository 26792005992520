import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SpecialRightsFormPart } from "components/special-rights/special-rights-form-part";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { fileToBase64 } from "utils/file-utils";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { SessionInfo } from "../models/session_info";
import { useNavigate, useParams } from "react-router";
import { SpecialRightsService} from "services/special-rights-service";
import { SpecialRightsFile } from "models/profile_data";
import { $t, t_key } from "plugins/i18n";
import Constants from "Constants";

export const SpecialRightsApprovalView = (props: {
  session: SessionInfo
}): JSX.Element => {
  const [specialRightsFiles, setSpecialRightsFiles] = useState<File[] | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoadError, setIsLoadError] = useState<boolean>(false);
  const [isFetchingApproval, setIsFetchingApproval] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { accessHash, dependantPersonCode } = useParams();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    special_rights_files: yup.mixed().test("files_required", $t(t_key.validation.special_rights_file_missing),
      function() {
        if (!specialRightsFiles) return false;
        if (specialRightsFiles?.length > 0) {
          return true;
        }
        return false;
      }
    ),
    guardian_first_name: yup.string(),
    guardian_last_name: yup.string(),
    guardian_person_code: yup.string()
  });

  const methods = useForm({
    mode:"onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      special_rights_files: [],
      guardian_first_name: "",
      guardian_last_name: "",
      guardian_person_code: "",
    }
  });

  useEffect(() => {
    if (!accessHash 
      || !props.session.application?.appId 
      || !dependantPersonCode) {
      return;
    }

    setIsFetchingApproval(true);

    SpecialRightsService.fetchSpecialRightsApproval({
      applicationId: props.session.application.appId,
      dependantPersonCode: dependantPersonCode,
      accessHash: accessHash
    }).then((resp: any) => {
        if (resp.isSuccess == false) {
          setIsLoadError(true)
          setErrorMessage(resp.message)
          return;
        }

        setIsLoadError(false)
        setErrorMessage("")

        if (resp.data?.guardian) {
          methods.setValue("guardian_first_name", resp.data.guardian.firstName)
          methods.setValue("guardian_last_name", resp.data.guardian.lastName)
          methods.setValue("guardian_person_code", resp.data.guardian.personCode)
        }

      }).catch((resp) => {
        if (resp.status === 401) {
          navigate(`/?app_id=${props.session.application?.appId}`)
        }

        setIsLoadError(true)
      }).finally(() => {
        setIsFetchingApproval(false)
      })
  }, [])

  useEffect(() => {
    const setFiles = async () => {
      if (specialRightsFiles) {
        let files = [];
        for (var i = 0; i < (specialRightsFiles?.length ?? 0); i++) {
          files.push({
            name: specialRightsFiles![i].name,
            data: await fileToBase64(specialRightsFiles![i]),
            size: specialRightsFiles![i].size
          });
        }
        methods.setValue("special_rights_files", files)
      }
    }
    setFiles();

  }, [specialRightsFiles])

  const onSubmit = (data: any) => {
    if (!props.session.application 
      || !dependantPersonCode
      || !accessHash) {
      return
    }

    if (!props.session.session?.person_code) return;

    setIsLoading(true);
    SpecialRightsService.approve({
      applicationId: props.session.application?.appId,
      body: {
        accessHash: accessHash,
        dependantPersonCode: dependantPersonCode,
        specialRightsFiles: data.special_rights_files as SpecialRightsFile[],
        approvedByPersonCode: props.session.session?.person_code
      }
    }).then((resp: any) => {
        if (resp.errors) {
          setIsError(true);
          setErrorMessage(resp.errors.error)
          return;
        }

        navigate(`/thank-you`, {
          state: {
            message: $t("thank_you.special_rights_approved")
          }
        });

      }).catch((resp) => {
        if (resp.status === 401) {
          navigate(`/?app_id=${props.session.application?.appId}`)
        }

        setIsError(true);
        setErrorMessage($t("message.error"));
      }).finally(() => {
        setIsLoading(false);
      })
  }

  const onSubmitError = (data: any) => {
    console.error(data);
  }

  const onDenyRequest = () => {
    if (!props.session.application 
      || !dependantPersonCode
      || !accessHash) {
      return
    }

    if (!props.session.session?.person_code) return;

    setIsLoading(true);
    SpecialRightsService.deny({
      applicationId: props.session.application?.appId,
      body: {
        accessHash: accessHash,
        dependantPersonCode: dependantPersonCode,
        guardianPersonCode: props.session.session?.person_code
      }
    }).then((resp: any) => {
        if (resp.errors) {
          setIsError(true);
          setErrorMessage(resp.errors.error)
          return;
        }

        navigate(`/thank-you`, {
          state: {
            message: $t("thank_you.special_rights_denied")
          }
        });
      }).catch((resp) => {
        if (resp.status === 401) {
          navigate(`/?app_id=${props.session.application?.appId}`)
        }

        setIsError(true);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  const renderCouldNotLoadApprovalRequestView = (): JSX.Element => {
    return (
      <Alert variant={"danger"} tabIndex={-1} className="page-alert mt-3">
        <div className="container">
          {errorMessage}
        </div>
      </Alert> 
    );
  }

  return (
    <Container>
      {isLoadError && !isFetchingApproval &&
        renderCouldNotLoadApprovalRequestView()
      }

      {isError &&
        <Alert variant={"danger"} tabIndex={-1} className="page-alert">
          <div className="container">
            {errorMessage}
          </div>
        </Alert> 
      }

      {!isLoadError && !isFetchingApproval &&
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit, onSubmitError)} className="data-form">
            <h1 id={Constants.MAIN_CONTENT}>{$t("special_rights_approval_view.title")}</h1>
            <h2 tabIndex={0} className="mt-3">{$t(t_key.register_view.your_data)}</h2>
            <Row className="mt-3">
              <Col xs={12} md={9} lg={6}>
                <Form.Group>
                  <Form.Label htmlFor="guardian-first-name">
                    {$t("field.first_name")}
                  </Form.Label>
                  <Form.Control
                    id="guardian-first-name"
                    readOnly={true}
                    {...methods.register("guardian_first_name")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={9} lg={6}>
                <Form.Group>
                  <Form.Label htmlFor="guardian-last-name">
                    {$t("field.last_name")}
                  </Form.Label>
                  <Form.Control
                    id="guardian-last-name"
                    readOnly={true}
                    {...methods.register("guardian_last_name")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={9} lg={6}>
                <Form.Group>
                  <Form.Label htmlFor="guardian-person-code">
                    {$t("field.person_code")}
                  </Form.Label>
                  <Form.Control
                    id="guardian-person-code"
                    readOnly={true}
                    {...methods.register("guardian_person_code")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12} md={9} lg={9}>
                <SpecialRightsFormPart 
                  setFiles={setSpecialRightsFiles} 
                  isUnderage={false}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12} className="form-buttons mt-3">
                <Button variant="dark" 
                  onClick={() => onDenyRequest()}
                  disabled={isLoading}
                >
                  {$t(t_key.buttons.cancel)}
                </Button>
                <Button type="submit" disabled={isLoading}>
                  {$t(t_key.buttons.confirm)}
                </Button>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      }
    </Container>
  )
}

