import { Col, Container, Row } from "react-bootstrap";
import { SessionInfo } from "../models/session_info";
import { $t, t_key } from "../plugins/i18n";

export type ServiceTerminatedViewProps = {
    session: SessionInfo
}

const ServiceTerminatedView = (props: ServiceTerminatedViewProps) => {
    return <Container className="content-area">
        <Row>
            <Col xs={12} className="col1">
                <h1 tabIndex={0}>{$t(t_key.service_terminated_view.title)}</h1>
            </Col>
        </Row>
    </Container>
}
ServiceTerminatedView.displayName = "ServiceTerminatedView"; //for chrome to show name instead of anonymous.

export default ServiceTerminatedView;
