import Constants from "Constants";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router";

export const ThankYou = (): JSX.Element => {
  const [message, setMessage] = useState<string>("");

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      // @ts-ignore
      setMessage(state.message)
    }
  }, [location])

  return(
    <Container>
      <h1 id={Constants.MAIN_CONTENT} className="mt-4" tabIndex={1}>
        {message}
      </h1>
    </Container>
  );

}
