import { createRef, KeyboardEventHandler, LegacyRef, MutableRefObject, RefObject, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { $t, $trans, t_key } from "../../plugins/i18n";
import UploadLogo from "../../assets/cloud-arrow-up.svg";
import { Controller, useFormContext } from "react-hook-form";

const AcceptedFileTypes = ["application/pdf","image/jpeg", "image/png", "bdoc", "asice", "application/vnd.etsi.asic-e+zip"]
const MAX_FILE_SIZE = 10_000_000;
const MAX_FILE_COUNT = 5;

export type SpecialRightsFormPartProps = {
  setFiles: React.Dispatch<React.SetStateAction<File[] | null>>,
  isUnderage: boolean,
} & typeof defaultProps;

const defaultProps = {
  isUnderage: false,
}

export const SpecialRightsFormPart = (props: SpecialRightsFormPartProps) => {
  const { control, clearErrors, setError, formState: {errors} } = useFormContext();
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [specialRightsFileErrors, setSpecialRightsFileErrors] = useState<string>("");

  const handleFileChanges = (): void => {
    clearErrors("special_rights_files")
    setSpecialRightsFileErrors("");

    const fileInput = document.getElementById("special-rights-file") as HTMLInputElement;
    if (fileInput == null || fileInput == undefined) return;

    if (fileInput.files === null) return;

    const fileCount = fileInput.files?.length;
    if (fileCount == undefined) return;

    var list: File[] = []
    if (fileCount + addedFiles.length > MAX_FILE_COUNT) {
      setSpecialRightsFileErrors($t(t_key.validation.max_file_count, {maxFileCount: MAX_FILE_COUNT}));
      return
    }

    for (var i = 0; i < fileCount; i++) {
      const file = fileInput.files[i];
      const fileExists = addedFiles.find(x => x.name === file.name);
      if (fileExists) {
        fileInput.value = "";
        continue;
      };

      let fileType: string | undefined = file.type;
      let fileExt: string | undefined = file.name.split(".").pop();

      if (!AcceptedFileTypes.find(x => x === fileType) && !AcceptedFileTypes.find(x => x === fileExt)) {
        setSpecialRightsFileErrors($t(t_key.validation.invalid_file_format))
        break;
      }

      if (file.size > MAX_FILE_SIZE) {
        setSpecialRightsFileErrors($t(t_key.validation.file_size_exceeds_limit,
          {fileMaxSize: "10MB"}));
        break;
      }

      list.push(file);
    }

    setAddedFiles([...addedFiles, ...list])
    fileInput.value = "";
  }

  useEffect(() => {
    props.setFiles(addedFiles);
  }, [addedFiles])


  const removeFile = (fileName: string): void => {
    var filteredArray = addedFiles.filter(x => x.name !== fileName);
    setAddedFiles(filteredArray);
    props.setFiles(addedFiles);
  }

  const onInputLabelKeyDown: KeyboardEventHandler<HTMLElement> = (e): void => {
    if (e.key === ' ') {
      e.preventDefault();

      const input = document.getElementById("special-rights-file");
      if (!input) return;
      input.click()
    }
  }

  return (
    <>
      <Row className="mb-3 mt-3">
        <Col xs={12} xl={8} lg={12}>
        { props.isUnderage 
          ? $trans(t_key.profile_view.special_request_service_desc_underage, {link: "https://epr.ee/et/pealeht/"})
          : $trans(t_key.profile_view.special_request_service_desc, {link: "https://epr.ee/et/pealeht/"})
        }
        </Col>
      </Row>

      { !props.isUnderage &&
        <>
          <Col xs={12} lg={4}>
            <Controller 
              name="special_rights_files"
              control={control}
              render={({field}) => (
                <Form.Group>
                  <Form.Label
                    id="custom-file-input-label"
                    htmlFor="special-rights-file"
                    className="special-rights-file-label mt-4"
                    tabIndex={0}
                    onKeyDown={(e) => onInputLabelKeyDown(e)}
                    role="button"
                    ref={field.ref}
                    onFocus={(e) => {
                      if (!e.target.classList.contains("special-rights-file-label-active")) {
                        e.target.classList.add("special-rights-file-label-active")
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.classList.contains("special-rights-file-label-active")) {
                        e.target.classList.remove("special-rights-file-label-active")
                      }
                    }}
                    aria-invalid={!!errors.special_rights_files}
                    aria-describedby={
                      "file-required-message"
                    }
                  >
                    <img src={UploadLogo} alt="Upload logo" aria-hidden={true} />
                    {$t(t_key.buttons.upload_file)}
                    <Form.Control
                      id="special-rights-file"
                      type="file"
                      role="button"
                      multiple
                      tabIndex={0}
                      accept="application/pdf,image/jpeg,image/png,.bdoc,.asice"
                      className="mb-2 special-rights-file"
                      onChange={handleFileChanges}
                      isInvalid={!!specialRightsFileErrors}
                      aria-invalid={!!errors.special_rights_files}
                      aria-describedby={
                        "file-required-message"
                      }
                    />
                  </Form.Label>
                </Form.Group>
              )}
              />
            { specialRightsFileErrors &&
              <Form.Text className="text-danger" role="alert">
                {specialRightsFileErrors}
              </Form.Text>
            }

            { errors.special_rights_files &&
              <Form.Text className="text-danger" role="alert" id="file-required-message">
                { errors.special_rights_files?.message as string }
              </Form.Text>
            }
          </Col>
        </>
      }

      <div className="file-bucket">
        {addedFiles.map(x => 
          <li key={x.name} className="file-list">
            <span className="mr-3">{x.name}</span>
            <Button variant="dark" className="remove-file-btn" onClick={() => removeFile(x.name)}>
              Eemalda
            </Button>
          </li>
        )}
      </div>

    </>
  );
}

SpecialRightsFormPart.defaultProps = defaultProps;
