import BackendApi from "backend-api";
import BackendConfig from "backend-config";
import { SpecialRightsFile } from "models/profile_data";

export const SpecialRightsService = {
  fetchSpecialRightsApproval(params: {
    applicationId: string
    accessHash: string, 
    dependantPersonCode: string
  }) {
    return new Promise((resolve, reject) => {
      const url = `${BackendConfig.Url()}/SpecialRightsApproval?appId=${params.applicationId}&accessHash=${params.accessHash}&dependantPersonCode=${params.dependantPersonCode}`;
      BackendApi.cleanGet(url)
        .then(resp => resolve(resp))
        .catch(ex => {
          reject(ex)
        });
    });
  },

  approve(params: {
    applicationId: string,
    body: {
      accessHash: string,
      dependantPersonCode: string,
      specialRightsFiles: SpecialRightsFile[],
      approvedByPersonCode: string
    }
  }) {
    return new Promise((resolve, reject) => {
      const url = `${BackendConfig.Url()}/SpecialRightsApproval/approve?appId=${params.applicationId}`;
      BackendApi.post(url, params.body)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  deny(params: {
    applicationId: string
    body: {
      accessHash: string
      dependantPersonCode: string,
      guardianPersonCode: string
    }
  }) {
    return new Promise((resolve, reject) => {
      const url = `${BackendConfig.Url()}/SpecialRightsApproval/deny?appId=${params.applicationId}`;
      BackendApi.post(url, params.body)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  }
}
