import { Col, Row, Button, Modal } from "react-bootstrap";
import { $t, t_key } from "../plugins/i18n";

type ErrorModalProps = {
	show: boolean,
	onClose: () => void,
	message: string
}

const ErrorModal = (props: ErrorModalProps) => {
	return <Modal show={props.show} onHide={props.onClose} className="ra-modal" size="lg">
		<Modal.Header closeButton>
			<Modal.Title>{$t(t_key.validation.error_modal_title)}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row className="mb-3">
				<Col xs={12}>
					<div>{props.message}</div>
				</Col>
			</Row>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={props.onClose}>
				{$t(t_key.buttons.close)}
			</Button>
		</Modal.Footer>
	</Modal>;
}

export default ErrorModal;
