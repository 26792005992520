
const IdCard = () => {
    return (<svg width="85" height="54" viewBox="0 0 85 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_391_2865)">
    <path d="M76.2996 54H8.70043C6.39361 53.9978 4.18192 53.0933 2.55076 51.485C0.919592 49.8766 0.00222344 47.6959 0 45.4214V8.57862C0.00222344 6.3041 0.919592 4.12336 2.55076 2.51504C4.18192 0.906708 6.39361 0.00219231 8.70043 0H76.2996C78.6064 0.00219231 80.8181 0.906708 82.4492 2.51504C84.0804 4.12336 84.9978 6.3041 85 8.57862V45.4214C84.9978 47.6959 84.0804 49.8766 82.4492 51.485C80.8181 53.0933 78.6064 53.9978 76.2996 54ZM8.70043 2.93683C7.18111 2.93829 5.72445 3.53402 4.65013 4.5933C3.57581 5.65258 2.97161 7.08885 2.97012 8.58689V45.4297C2.97161 46.9277 3.57581 48.364 4.65013 49.4233C5.72445 50.4825 7.18111 51.0783 8.70043 51.0797H76.2996C77.8186 51.0775 79.2749 50.4816 80.349 49.4224C81.4232 48.3633 82.0277 46.9275 82.0299 45.4297V8.57862C82.0277 7.0808 81.4232 5.64494 80.349 4.58582C79.2749 3.5267 77.8186 2.93073 76.2996 2.92854L8.70043 2.93683Z" fill="#090688"/>
    <path d="M45.6381 47.6047H42.668V32.076C42.6672 31.3039 42.3558 30.5637 41.8021 30.0177C41.2484 29.4718 40.4977 29.1647 39.7146 29.164H18.0783C17.2953 29.1647 16.5445 29.4718 15.9908 30.0177C15.4371 30.5637 15.1257 31.3039 15.125 32.076V40.6574H12.1549V32.076C12.1563 30.5275 12.7809 29.0427 13.8914 27.9477C15.002 26.8527 16.5078 26.2369 18.0783 26.2355H39.7146C41.2852 26.2369 42.791 26.8527 43.9015 27.9477C45.0121 29.0427 45.6366 30.5275 45.6381 32.076V47.6047Z" fill="#090688"/>
    <path d="M28.8951 23.6685C27.3104 23.6685 25.7613 23.2051 24.4437 22.337C23.1261 21.4688 22.0992 20.2349 21.4929 18.7913C20.8866 17.3477 20.7281 15.7592 21.0374 14.2267C21.3468 12.6943 22.1101 11.2867 23.2309 10.182C24.3516 9.07735 25.7794 8.32522 27.3338 8.02072C28.8881 7.71623 30.4991 7.87305 31.963 8.47137C33.4269 9.0697 34.678 10.0827 35.558 11.3821C36.438 12.6816 36.9074 14.2091 36.9069 15.7716C36.9046 17.8658 36.0597 19.8735 34.5576 21.3541C33.0555 22.8347 31.019 23.667 28.8951 23.6685ZM28.8951 10.8006C27.8985 10.8006 26.9243 11.0919 26.0956 11.6379C25.267 12.1838 24.6212 12.9597 24.2398 13.8676C23.8584 14.7754 23.7586 15.7744 23.953 16.7381C24.1475 17.7019 24.6274 18.5872 25.3321 19.282C26.0368 19.9768 26.9346 20.45 27.912 20.6417C28.8895 20.8334 29.9026 20.735 30.8234 20.359C31.7441 19.983 32.5311 19.3462 33.0847 18.5291C33.6384 17.7121 33.9339 16.7515 33.9339 15.7689C33.9339 14.4512 33.4031 13.1875 32.4581 12.2558C31.5131 11.324 30.2315 10.8006 28.8951 10.8006Z" fill="#090688"/>
    <path d="M72.4784 12.683H50.8141V15.6115H72.4784V12.683Z" fill="#090688"/>
    <path d="M72.4784 20.4032H50.8141V23.3317H72.4784V20.4032Z" fill="#090688"/>
    </g>
    <defs>
    <clipPath id="clip0_391_2865">
    <rect width="85" height="54" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    );
}

export default IdCard;