import * as React from "react";
export default function AppStart() {
    const startApp = async () => {
        //Load and cache data from server
    };

    const a = 1;
    React.useEffect(() => { startApp(); }, [a]);

    return (<></>);
}

AppStart.displayName = "AppStart"; //for chrome to show name instead of anonymous.