import BackendApi from "../backend-api";
import BackendConfig from "../backend-config";
import { AuthorizeInfoResponse } from "../models/authorize_info";
import { RedirectResponse } from "../models/redirect_response";
import { UserStatus } from "../models/session_info";
import { getCookie } from "../plugins/cookie-helper";

export const AuthService = {
    authorizeTara: async function (authType: string, locale: string, app_id: string, returnUrl?: string | null): Promise<AuthorizeInfoResponse> {
        let queryParams = new URLSearchParams();
        //TODO 76
        //const cookieValue = document.cookie.split('; ').find(row => row.startsWith('challenge='))?.split('=')[1] ?? "";
        const cookieValue = getCookie("challenge");
        queryParams.set("locale", locale);
        if (cookieValue)
            queryParams.set("challenge", cookieValue);
        queryParams.set("app_id", app_id);
        queryParams.set("scope", authType);
        if (returnUrl)
            queryParams.set("return_url", returnUrl);

        const url = BackendConfig.Url() + "/Auth/Tara?" + queryParams.toString();
        const result: AuthorizeInfoResponse = await BackendApi.get(url);

        window.open(result.authorize_url, "_self");
        
        return result;
    },
    taraSuccess: async function (code: string, state: string, challenge: string | null): Promise<RedirectResponse> {
        let queryParams = new URLSearchParams();
        if (challenge != null && challenge != "null")
            queryParams.set("challenge", challenge);
        queryParams.set("code", code);
        queryParams.set("state", state);

        const url = BackendConfig.Url() + "/auth/TaraSuccess?" + queryParams.toString();
        const result: RedirectResponse = await BackendApi.get(url);
        if (result.session?.user_status === UserStatus.NoAccount || result.session?.user_status === UserStatus.NoAppRegistered) { }  // Do not redirect if user has no account
        else if (result.error) {
          return { error: result.error } as RedirectResponse;
        }
        return result;
    },
    authorizeHarId: async function (locale: string, app_id: string) {
        let queryParams = new URLSearchParams();
        //const cookieValue = document.cookie.split('; ').find(row => row.startsWith('challenge='))?.split('=')[1] ?? "";
        const cookieValue = getCookie("challenge");
        queryParams.set("locale", locale);
        if (cookieValue != null && cookieValue != "null")
            queryParams.set("challenge", cookieValue);
        queryParams.set("app_id", app_id);

        const url = BackendConfig.Url() + "/Auth/HarId?" + queryParams.toString();
        const result: AuthorizeInfoResponse = await BackendApi.get(url);

        console.log(result);
        window.open(result.authorize_url, "_self");

        return result;
  },  
    harIdSuccess: async function (code: string, state: string, challenge: string | null): Promise<RedirectResponse> {
        let queryParams = new URLSearchParams();
        if (challenge != null && challenge != "null")
            queryParams.set("challenge", challenge);
        queryParams.set("code", code);
        queryParams.set("state", state);

        const url = BackendConfig.Url() + "/auth/HarIdSuccess?" + queryParams.toString();
        const result: RedirectResponse = await BackendApi.get(url);
        if (result.session?.user_status === UserStatus.NoAccount || result.session?.user_status === UserStatus.NoAppRegistered) { }  // Do not redirect if user has no account
        
        return result;
  },
  authorizeTAAT: async function (locale: string, app_id: string) {
    let queryParams = new URLSearchParams();
    const cookieValue = getCookie("challenge");
    queryParams.set("locale", locale);
    if (cookieValue != null && cookieValue != "null")
      queryParams.set("challenge", cookieValue);
    queryParams.set("app_id", app_id);

    const url = BackendConfig.Url() + "/Auth/taat/samlrequest?" + queryParams.toString();
    const result: AuthorizeInfoResponse = await BackendApi.get(url);

    console.log(result);
    window.open(result.authorize_url, "_self");

    return result;
  },
  TAATSuccess: async function (relayState: string | null): Promise<RedirectResponse> {
    let queryParams = new URLSearchParams();
    if (relayState)
      queryParams.set("RelayState", relayState);

    const url = BackendConfig.Url() + "/Auth/taat/acs/success?" + queryParams.toString();
    const result: RedirectResponse = await BackendApi.get(url);

      if (result.session?.user_status === UserStatus.NoAccount || result.session?.user_status === UserStatus.NoAppRegistered) { }  // Do not redirect if user has no account
    
    return result;
  },
  authorizeFailure: async function (error: string | null, description: string | null) {
    let queryParams = new URLSearchParams();
    queryParams.set("error", error ?? '');
    queryParams.set("description", description ?? '');

    const url = BackendConfig.Url() + "/auth/Failure?" + queryParams.toString();
    await BackendApi.get(url);
  },
    authorizePass: function (locale: string, app_id: string, username: string, password: string, captchaToken: string | null, returnUrl?: string | null) {
        return new Promise((resolve, reject) => {
            const challenge = getCookie("challenge");
            let queryParams = new URLSearchParams();
            queryParams.set("locale", locale);
            queryParams.set("app_id", app_id);
            if (returnUrl)
                queryParams.set("return_url", returnUrl);

            let reqData = { username, password, challenge, token: captchaToken };

            const url = BackendConfig.Url() + "/Auth/Login?" + queryParams.toString();
            BackendApi.cleanPost(url, reqData)
                .then(resp => {
                    if (resp.session?.user_status === UserStatus.NoAccount || resp.session?.user_status === UserStatus.NoAppRegistered) { }  // Do not redirect if user has no account
                    else {
                        window.open(resp.redirectUrl, "_self");
                    }
                    resolve(resp);
				})
                .catch(ex => reject(ex));
        });
	}
}
