import i18n from "i18next";
import { initReactI18next, Trans } from "react-i18next";
import httpBackend from "i18next-http-backend";
import BackendConfig from "../backend-config";
import { matchPath } from "react-router";
import Constants from "../Constants";

const detectLanguage = (defaultLng: string) => {
	let urlParams = new URLSearchParams(location.search);
	let lng = urlParams.get("lng");
	if (lng) {
		if (["en", "et", "ru"].indexOf(lng) >= 0) {
			sessionStorage.setItem(Constants.LANGUAGE, lng);
			return lng;
		}
	} else {
		//check if lng is on oidc logout url /oidc/:lng/logout
		//This hack was created for apps

		const pathMatch = matchPath({ path: "oidc/:lng/endsession" }, window.location.pathname);
		lng = pathMatch == null ? null : pathMatch.params["lng"] as string;		
		if (lng && ["en", "et", "ru"].indexOf(lng) >= 0)
			return lng;
	}

	const storedLang = sessionStorage.getItem(Constants.LANGUAGE);
	if (storedLang)
		return storedLang;

	return defaultLng;
}

i18n
	.use(httpBackend)
	.use(initReactI18next)
	.init({
		debug: false,
		lng: detectLanguage("et"),
		fallbackLng: false,
		interpolation: {
			escapeValue: false,
		},
		backend: { loadPath: BackendConfig.Url()  + "/Translation?locale={{lng}}" },
		react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ['a', 'strong', 'br'],
      transSupportBasicHtmlNodes: true,
      defaultTransParent: "div"
		},
	});

export const $t = (key: string, opts?: {}) => {
	return i18n.t(key, opts);
}

export const $trans = (key: string, values?: {}) => {
  return <Trans i18nKey={key}
    values={values}
    components={{a: <a />}}
  />
}

export const t_key = {
	language: {
		short: "language.short."
	},
  tooltip: {
    change_contrast: "tooltip.change_contrast",
    increase_font: "tooltip.increase_font",
    decrease_font: "tooltip.decrease_font",
    change_line_spacing: "tooltip.change_line_spacing",
    change_word_spacing: "tooltip.change_word_spacing",
    reset_settings: "tooltip.reset_settings"
  },
	buttons: {
		send: "buttons.send",
		save: "buttons.save",
		close: "buttons.close",
		cancel: "buttons.cancel",
		confirm: "buttons.confirm",
		register_account: "buttons.register_account",
		confirm_and_sign: "buttons.confirm_and_sign",
		continue: "buttons.continue",
		login_with_another_account: "buttons.login_with_another_account",
		login_with_strong_auth: "buttons.login_with_strong_auth",
		back: "buttons.back",
		change_password: "buttons.change_password",
		go_to_login: "buttons.go_to_login",
		retry: "buttons.retry",
		back_to_service: "buttons.back_to_service",
		go_to_main_content: "buttons.go_to_main_content",
		remove_file: "buttons.remove_file",
    terminate_service: "buttons.terminate_service",
    upload_file: "buttons.upload_file"
	},
	field: {
		mobile_phone: "field.mobile_phone",
		person_code: "field.person_code",
    mobile_phone_desc: "field.mobile_phone_desc",
	},
	validation: {
		required_field: "validation.required_field",
		invalid_email: "validation.invalid_email",
		invalid_person_code: "validation.invalid_person_code",
		invalid_date: "validation.invalid_date",
		required_to_confirm: "validation.required_to_confirm",
		passwords_dont_match: "validation.passwords_dont_match",
		password_requirements: "validation.password_requirements",
		password_requirements_no_triple_char: "validation.password_requirements_no_triple_char",
		password_requirements_no_word_pair: "validation.password_requirements_no_word_pair",
		password_requirements_one_number: "validation.password_requirements_one_number",
		password_requirements_one_upper: "validation.password_requirements_one_upper",
		password_requirements_one_lower: "validation.password_requirements_one_lower",
		password_requirements_length: "validation.password_requirements_length",
		login_failed: "validation.login_failed",
		invalid_data: "validation.invalid_data",
		unknown_application: "validation.unknown_application",
		user_not_found: "validation.user_not_found",
		invalid_challenge: "validation.invalid_challenge",
		error_modal_title: "validation.error_modal_title",
		user_is_not_logged_in: "validation.user_is_not_logged_in",
		cant_unregister_user_has_fines: "validation.cant_unregister_user_has_fines",
		cant_unregister_unable_to_load_fines: "validation.cant_unregister_unable_to_load_fines",
		cant_unregister_user_has_checkouts: "validation.cant_unregister_user_has_checkouts",
		cant_unregister_unable_to_load_checkouts: "validation.cant_unregister_unable_to_load_checkouts",
		error_occured: "validation.error_occured",
		cant_register_underage: "validation.cant_register_underage",
		no_rights_to_register_underage: "validation.no_rights_to_register_underage",
		person_codes_doesnt_match: "validation.person_codes_doesnt_match",
		unable_to_connect_service: "validation.unable_to_connect_service",
		unknown_error: "validation.unknown_error",
		captcha_is_not_included: "validation.captcha_is_not_included",
		invalid_captcha: "validation.invalid_captcha",
		only_the_latin_alphabet_is_allowed: "validation.only_the_latin_alphabet_is_allowed",
		max_file_size: "validation.max_file_size",
		max_file_count: "validation.max_file_count",
		special_rights_application_exists: "validation.special_rights_application_exists",
		signing_error: "signing_error",
    has_pending_special_request: "validation.has_pending_special_request",
    file_size_exceeds_limit: "validation.file_size_exceeds_limit",
    exceeds_max_file_count: "validation.exceeds_max_file_count",
    invalid_phone_number: "validation.invalid_phone_number",
    invalid_phone_missing_country_code: "validation.invalid_phone_missing_country_code",
    invalid_file_type: "validation.invalid_file_type",
    special_rights_file_missing: "validation.special_rights_file_missing",
    invalid_file_format: "validation.invalid_file_format",
    invalid_phone_nr_spaces_not_allowed: "validation.invalid_phone_nr_spaces_not_allowed"
	},
	login_view: {
		accessability: 'login_view.accessability',
		title: "login_view.title",
		description: "login_view.description",
		login_with_: "login_view.login_with_",
		_id_card: "login_view._id_card",
		_mobile_id: "login_view._mobile_id",
		_smart_id: "login_view._smart_id",
		_eidas_id: "login_view._eidas_id",
		login_with_harid: "login_view.login_with_harid",
		login_with_taat: "login_view.login_with_taat",
		login_with_password: "login_view.login_with_password",
		unknown_service: "login_view.unknown_service",
		username: "login_view.username",
		password: "login_view.password",
		login: "login_view.login",
		forgot_password: "login_view.forgot_password",
		register_for_minor: "login_view.register_for_minor",
		register_for_minor_link: "login_view.register_for_minor_link",
		rara_register_for_minor_title: "login_view.rara_register_for_minor_title",
		national_register_for_minor_title: "login_view.national_register_for_minor_title",
		rara_register_for_minor_description: "login_view.rara_register_for_minor_description",
		national_register_for_minor_description: "login_view.national_register_for_minor_description",
		soft_login_requires_registration_title: "login_view.soft_login_requires_registration_title",
		soft_login_requires_registration_description: "login_view.soft_login_requires_registration_description",
	},
	logout_view: {
		only_this_btn_text: 'logout_view.only_this_btn_text',
		all_btn_text: 'logout_view.all_btn_text',
	},
	forgot_password_view: {
		title: "forgot_password_view.title",
		message: "forgot_password_view.message",
		e_mail_address: "forgot_password_view.e_mail_address",
		success_title: "forgot_password_view.success_title",
		success_description: "forgot_password_view.success_description",
		failed_title: "forgot_password_view.failed_title",
		failed_description: "forgot_password_view.failed_description"
	},
	register_modal: {
		title: "register_modal.title",
		title_no_reg: "register_modal.title_no_reg",
		description: "register_modal.description",
		description_no_reg: "register_modal.description_no_reg",
		title_underage: "register_modal.title_underage",
		description_underage: "register_modal.description_underage",
		title_approval: "register_modal.title_approval",
		description_approval: "register_modal.description_approval",
		strong_auth_is_required: "register_modal.strong_auth_is_required",  // uus
	},
	sign_terms_modal: {
		title: "sign_terms_modal.title",
		description: "sign_terms_modal.description",
	},
	register_view: {
		title: "register_view.title",
		service: "register_view.service",
		your_data: "register_view.your_data",
		login_with_password: "register_view.login_with_password",
		foreign_address: "register_view.foreign_address",
		foreign_county: "register_view.foreign_county",
		timeout: "register_view.timeout",
		foreign_municipality: "register_view.foreign_municipality",
		foreign_city: "register_view.foreign_city",
		foreign_street: "register_view.foreign_street",
		confirm_that_i_have_read: "register_view.confirm_that_i_have_read",
		i_accept_service: "register_view.i_accept_service",
		personal_data_processing_procedure: "register_view.personal_data_processing_procedure",
		service_terms: "register_view.service_terms",
		documents_to_sign: "register_view.documents_to_sign",
		registration_application_pdf: "register_view.registration_application_pdf",
		unregistration_application_pdf: "register_view.unregistration_application_pdf",
		service_terms_pdf: "register_view.service_terms_pdf",
		personal_data_processing_procedure_pdf: "register_view.personal_data_processing_procedure_pdf",
		consent_form_pdf: "register_view.consent_form_pdf",
		password: "register_view.password",
		repeat_password: "register_view.repeat_password",
		parent_data: "register_view.parent_data",
		child_data: "register_view.child_data",
		foreign_sign_notification: "register_view.foreign_sign_notification",
		select_empty: "register_view.select_empty",
		request_special_rights: "register_view.request_special_rights",
		request_special_rights_content: "register_view.request_special_rights_content",
		request_special_rights_content_underage: "register_view.request_special_rights_content_underage",
		upload_special_rights_file: "register_view.upload_special_rights_file",
	},
	parent_approval_view: {
		title: "parent_approval_view.title",
		your_data: "parent_approval_view.your_data",
		no_access: "parent_approval_view.no_access",
		no_access_details: "parent_approval_view.no_access_details",
		child: "parent_approval_view.child",
	},
	signing_method_modal: {
		title: "signing_method_modal.title",
		phone_number: "signing_method_modal.phone_number",
		identification_code: "signing_method_modal.identification_code",
		signed_documents: "signing_method_modal.signed_documents",
		error_during_signing: "signing_method_modal.error_during_signing",
		choose_sign_method: "signing_method_modal.choose_sign_method",
		description: "signing_method_modal.description",
		proceed_to_service: "signing_method_modal.proceed_to_service"
	},
	signing_success_modal: {
		title: "signing_success_modal.title",
		body: "signing_success_modal.body",
		unregisterTitle: "signing_success_modal.unregistertitle",
		unregisterBody: "signing_success_modal.unregisterbody",
	},
	login_failed_text: {
		message: "login_failed_text.message"
	},
	saml_login_success_view: {
		message: "saml_login_success_view.message"
	},
	tara_login_success_view: {
		message: "tara_login_success_view.message"
	},
	harid_login_success_view: {
		message: "harid_login_success_view.message"
	},
	taat_login_success_view: {
		message: "taat_login_success_view.message"
	},
	profile_view: {
		title: "profile_view.title",
		person_code: "profile_view.person_code",
		local_gov: "profile_view.local_gov",
		on_date: "profile_view.on_date",
		terminate_service: "profile_view.terminate_service",
		are_you_sure_to_terminate_service: "profile_view.are_you_sure_to_terminate_service",
		name: "profile_view.name",
		username: "profile_view.username",
		usernames: "profile_view.usernames",
    parent_data: "profile_view.parent_data",
    special_request_service_title: "profile_view.special_request_service_title",
    special_request_service_desc: "profile_view.special_request_service_desc",
    special_request_service_desc_underage: "profile_view.special_request_service_desc_underage",
    special_request_status_prefix: "profile_view.special_request_status_prefix",
    special_request_status_pending: "profile_view.special_request_status_pending",
    special_request_status_accepted: "profile_view.special_request_status_accepted",
    special_request_status_date_indefinite: "profile_view.special_request_status_date_indefinite",
    change_or_login_with_password: "profile_view.change_or_login_with_password",
	},
	service_terminated_view: {
		title: "service_terminated_view.title"
	},
	password_reset_view: {
		failed: "password_reset_view.failed",
		title: "password_reset_view.title",
		details: "password_reset_view.details",
		password: "password_reset_view.password",
		repeat_password: "password_reset_view.repeat_password",
		password_changed: "password_reset_view.password_changed"
	},
	login_failed_page: {
		message: "login_failed_page.message",
	},
  general: {
    save_success: "general.save_success",
    save_error: "general.save_error",
    service_error: "general.service_error",
  },
  menu: {
    change_language_to_est: "menu.change_language_to_est",
    change_language_to_eng: "menu.change_language_to_eng",
    change_language: "menu.change_language"
  }
}

export default i18n;
