import DownloadIcon from "components/icons/download-icon";
import { $t, t_key } from "plugins/i18n";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalProviderContext } from "../modal-provider";
import BackendApi from "backend-api";
import BackendConfig from "backend-config";
import Constants from "../../../Constants";

type SigningSuccessProps = {
  containerId: string;
  onClose?: () => void;
  proceedToService?: boolean;
  isUnRegister?: boolean;
  redirectUrl?: string;
}

const SigningSuccessModal = (props: SigningSuccessProps) => {
  const { hideModal } = useContext(ModalProviderContext);

  const downloadContainer = () => {
    BackendApi.getAndDownload(BackendConfig.Url() + `/siGa/DownloadContainer?cacheKey=${props.containerId}`,
      `${$t(t_key.signing_method_modal.signed_documents)}.bdoc`);
  }

  let tab: any; 
  const closeModal = () => {
    if (!!props.onClose) {
      if (props.isUnRegister) {
        let jsonResponse: any;
        try {
          const response: any = sessionStorage.getItem(Constants.LOGOUT_URLS);
          if (response) {
            jsonResponse = JSON.parse(response);
          }
        } catch (e: any) {
          console.error(e.message)
        }

        if (jsonResponse?.otherAppsLogoutUrls && jsonResponse.otherAppsLogoutUrls.length > 0) {
          let i = 1;
          for (const element of jsonResponse.otherAppsLogoutUrls) {
            const logoutUrl = element;
            try {
              if (!tab) {
                tab = window.open(logoutUrl, '_blank');
              } else {
                window.setTimeout(() => {
                  tab.location.href = logoutUrl;
                }, i * 2000);
                i++;
              }
            } catch (e: any) {
              console.error(e.message)
            }
          }
         
          window.setTimeout(() => {
            if (tab) {
              tab.close();
            }

            sessionStorage.removeItem(Constants.LOGOUT_URLS);
            if (props.onClose) {
              props.onClose();
            }
          }, i * 2000);
        } else if (props.onClose) {          
            props.onClose();
        }
      } else {
        if (props.onClose) {
          props.onClose();
        }
      }
    } 

    hideModal();
  }

  const proceedToService = () => {
    if (props.proceedToService) {
      console.log("final: ", props.redirectUrl)
      window.open(props.redirectUrl, "_self");
    }
  }

  const onModalHide = () => {
    if (props.proceedToService)
      proceedToService();
    else {
      closeModal();
    }
  }

  return (
    <Modal show onHide={() => { onModalHide(); }} className="ra-modal success-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {props.isUnRegister ? $t(t_key.signing_success_modal.unregisterTitle) : $t(t_key.signing_success_modal.title)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">{props.isUnRegister ? $t(t_key.signing_success_modal.unregisterBody) : $t(t_key.signing_success_modal.body)}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="d-flex align-items-center btn-icon-left" onClick={downloadContainer}>
          <DownloadIcon />
          <span>{$t(t_key.signing_method_modal.signed_documents)}</span>
        </Button>
        {props.proceedToService ? <Button variant="primary" className="d-flex align-items-center btn-icon-left" onClick={proceedToService}>
          <span>{$t(t_key.signing_method_modal.proceed_to_service)}</span>
        </Button> : <Button variant="primary" onClick={() => { closeModal(); }}>
          {$t(t_key.buttons.close)}
        </Button>}
      </Modal.Footer>
    </Modal >
  )
}

export default SigningSuccessModal;
