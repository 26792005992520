import React, {LegacyRef, useContext } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SessionInfo, UserStatus } from "../../models/session_info";
import { $t, t_key } from "../../plugins/i18n";
import {
  hideValidationErrors,
  showError,
  showValidationErrors,
} from "../../plugins/validation";
import { AuthService } from "../../services/auth-service";
import ForgotPasswordModal from "./forgot-password-modal";
import ReCAPTCHA from "react-google-recaptcha";
import { ModalProviderContext } from "components/modals/modal-provider";
import ErrorModal from "components/modals/error-modal";

type LoginFormData = {
  username: string;
  password: string;
};

export type LoginPasswordControlProps = {
  session: SessionInfo;
  underage?: boolean;
};

const LoginPasswordControl = (props: LoginPasswordControlProps) => {
  const { i18n } = useTranslation();

  const recaptchaRef: LegacyRef<ReCAPTCHA> = React.createRef();
  const [formData, setFormData] = React.useState<LoginFormData>({
    username: "",
    password: "",
  });
  const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({
    username: null,
    password: null,
    global: null,
  });
  const [forgotPass, setForgotPass] = React.useState<boolean>(false);
  const [requiresCaptcha, setRequiresCaptcha] = React.useState<boolean>(false);
  const { showModal } = useContext(ModalProviderContext);

  const setField = (val: string, fld: string) => {
    let tmp = { ...formData } as { [key: string]: any };
    tmp[fld] = val;
    setFormData(tmp as LoginFormData);
  };
  const loginClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData || props.session.application == null) return;

    let token = null;
    if (recaptchaRef?.current != null) {
      token = recaptchaRef.current.getValue();
      recaptchaRef.current.reset();
    }

    hideValidationErrors(localErrors, setLocalErrors);
    let returnUrl = props.underage
      ? `/services/${props.session.application?.appId}/register_underage/`
      : null;
    AuthService.authorizePass(
      i18n.language,
      props.session.application.appId,
      formData.username,
      formData.password,
      token,
      returnUrl
    )
      .then((resp) => {
        showValidationErrors(resp, setLocalErrors);
        if ((resp as any).errors?.requires_captcha != null)
          setRequiresCaptcha(true);
        if (
          (resp as any).session?.user_status === UserStatus.Unauthorized &&
          (resp as any).redirectUrl == null
        ) {
          showModal(
            <ErrorModal
              title={$t(
                t_key.login_view.soft_login_requires_registration_title
              )}
              content={$t(
                t_key.login_view.soft_login_requires_registration_description
              )}
            />
          );
        }
      })
      .catch((ex) => showError(ex));
  };
  const forgotPassClick = () => {
    setForgotPass(true);
    hideValidationErrors(localErrors, setLocalErrors);
  };

  React.useEffect(() => {
    setRequiresCaptcha(props.session.requires_captcha);
  }, []);

  return (
    <React.Fragment>
      {forgotPass ? (
        <ForgotPasswordModal
          show={forgotPass}
          session={props.session}
          onClose={() => setForgotPass(false)}
        />
      ) : null}
      <form onSubmit={loginClick}>
        <Row>
          <Col xs={12}>
            {localErrors.global ? (
              <div role={"alert"} className="local-error mt-3">{$t(localErrors.global)}</div>
            ) : null}
          </Col>
          <Col xs={12} className="mt-4">
            <Form.Group controlId="name">
              <Form.Control
                value={formData.username}
                isInvalid={!!localErrors.username}
                placeholder={$t(t_key.login_view.username)}
                title={$t(t_key.login_view.username)}
                aria-describedby={
                  !localErrors.username ? "" : $t(localErrors.username)
                }
                aria-invalid={!!localErrors.username}
                onChange={(e) => setField(e.target.value, "username")}
              />
              {!!localErrors.username ? (
                <Form.Control.Feedback type="invalid" role={"alert"}>
                  {localErrors.username}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-4">
            <Form.Group className="mb-3" controlId="password">
              <Form.Control
                type="password"
                value={formData.password}
                isInvalid={!!localErrors.password}
                placeholder={$t(t_key.login_view.password)}
                title={$t(t_key.login_view.password)}
                aria-describedby={
                  !localErrors.password ? "" : $t(localErrors.password)
                }
                aria-invalid={!!localErrors.password}
                onChange={(e) => setField(e.target.value, "password")}
              />
              {!!localErrors.password ? (
                <Form.Control.Feedback type="invalid" role={"alert"}>
                  {localErrors.password}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          {requiresCaptcha ? (
            <Col xs={12} className="re-captcha-cont mb-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={props.session.recaptcha_site_key}
                hl={i18n.language}
              />
            </Col>
          ) : null}
          <Col xs={12} className="text-center mb-3">
            <Button variant="primary" type="submit">
              {$t(t_key.login_view.login)}
            </Button>
          </Col>
          <Col xs={12} className="text-center">
            <Button variant="link" onClick={forgotPassClick}>
              {$t(t_key.login_view.forgot_password)}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default LoginPasswordControl;
