import { Button, Col, Modal, Row } from "react-bootstrap";
import { ApplicationInfo } from "../../models/application_info";
import { $t, t_key } from "../../plugins/i18n";

type ParentApprovalNoAccessModalProps = {
	show: boolean,
	app: ApplicationInfo,
	onClose(): void,
	onLogin(): void,
}

const ParentApprovalNoAccessModal = (props: ParentApprovalNoAccessModalProps) => {
	return <Modal show={props.show} onHide={props.onClose} className="ra-modal" size="lg">
		<Modal.Header closeButton>
			<Modal.Title>{$t(t_key.parent_approval_view.no_access)}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row className="mb-3">
				<Col xs={12}>
					<div>{$t(t_key.parent_approval_view.no_access_details)}</div>
				</Col>
			</Row>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={props.onClose}>
				{$t(t_key.buttons.close)}
			</Button>
			<Button variant="primary" onClick={props.onLogin}>
				{$t(t_key.buttons.login_with_another_account)}
			</Button>
		</Modal.Footer>
	</Modal>;
}

export default ParentApprovalNoAccessModal;
