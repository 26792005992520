const Constants = {
  USER_SESSION_ID: "USER_SESSION_ID",
  SERVICE_TYPE_RARA: "rara",
  SERVICE_TYPE_NATIONAL: "national",
  URL_REFERRER: "R",
  LOGOUT_URLS: "LOGOUT_URLS",
  LANGUAGE: "lng",
  LANGUAGES: "lngs",
  DESIGN: "DESIGN",
  MAIN_CONTENT: "main-content"
};
export default Constants;

