import { useEffect, useState } from "react";
import classNames from "classnames";
import { $t, t_key } from "plugins/i18n";
import { InterfaceSettings, InterfaceSettingKey } from "interface-control-settings";
import Constants from "../Constants";

const BASE_FONT_SIZE_PERCENTAGE = 62.5;
const FONT_SIZE_PERCENTAGE_THRESHOLD = 160;
const FONT_INCREASE_INCREMENT_PERCENTAGE = 25;

export const InterfaceControls = ({
  isMobile = false,
}): JSX.Element => {
  const [fontSize, setFontSize] = useState<number>(BASE_FONT_SIZE_PERCENTAGE);
	const storedTheme = sessionStorage.getItem(Constants.DESIGN) ?? "rara";

  useEffect(() => {
    var fontFromLocalStorage = localStorage.getItem(InterfaceSettingKey.FONT_SIZE);
    if (fontFromLocalStorage) {
      setFontSize(+fontFromLocalStorage)
    }
  }, [])

  const resetSettings = (): void => {
    setFontSize(BASE_FONT_SIZE_PERCENTAGE);
    document.body.className =`theme-${storedTheme}`;

    InterfaceSettings.clearAll();
  }

  const increaseLineHeight = (): void => {
    document.body.classList.toggle("increased-line-spacing")

    if (document.body.classList.contains("increased-line-spacing")) {
      localStorage.setItem(InterfaceSettingKey.IS_INCREASED_LINE_HEIGHT, "increased-line-spacing");
    } else {
      localStorage.removeItem(InterfaceSettingKey.IS_INCREASED_LINE_HEIGHT);
    }
  }

  const toggleWordSpacing = (): void => {
    document.body.classList.toggle("increased-word-spacing")

    if (document.body.classList.contains("increased-word-spacing")) {
      localStorage.setItem(InterfaceSettingKey.IS_INCREASED_WORD_SPACING, "increased-word-spacing");
    } else {
      localStorage.removeItem(InterfaceSettingKey.IS_INCREASED_WORD_SPACING);
    }
  }

  const toggleHighContrast = (): void => {
    var body = document.getElementsByTagName("body")[0];
    if (!body) return;

    if (body.classList.contains("high-contrast")) {
      body.classList.remove("high-contrast");
      localStorage.removeItem(InterfaceSettingKey.IS_HIGH_CONTRAST);

      if (storedTheme === "mirko") {
        body.classList.add("theme-mirko");
        body.classList.remove("theme-rara");
      }

      if (storedTheme === "rara") {
        body.classList.add("theme-rara");
        body.classList.remove("theme-mirko");
      }
    } else {
      body.classList.remove("theme-mirko");
      body.classList.remove("theme-rara");
      body.classList.add("high-contrast");

      localStorage.setItem(InterfaceSettingKey.IS_HIGH_CONTRAST, "high-contrast");
    }
  }

  const increaseFont = (): void => {
    if (fontSize > FONT_SIZE_PERCENTAGE_THRESHOLD) return;
    setFontSize(fontSize + FONT_INCREASE_INCREMENT_PERCENTAGE);
  }

  const decreaseFont = (): void => {
    if (fontSize == BASE_FONT_SIZE_PERCENTAGE) return;
    setFontSize(fontSize - FONT_INCREASE_INCREMENT_PERCENTAGE);
  }

  useEffect(() => {
    document.documentElement.style.fontSize = fontSize + "%";
    localStorage.setItem(InterfaceSettingKey.FONT_SIZE, `${fontSize}`);
  }, [fontSize])

  return (
    <div className={
      classNames("interface-control-wrapper",
        {"interface-control-wrapper--mobile": isMobile})
    }>
      <div className="interface-control" tabIndex={0}
        role="button" 
        onClick={toggleHighContrast}
        onKeyDown={(e) => e.key === "Enter" && toggleHighContrast()}
      >
        {!isMobile &&
          <span className="tooltip">{$t(t_key.tooltip.change_contrast)}</span>
        }
        <img className="toggle-contrast-img" alt="contrast"/>
      </div>
      <div className="interface-control" 
        role="button" 
        onClick={increaseLineHeight} tabIndex={0}
        onKeyDown={(e) => e.key === "Enter" && increaseLineHeight()}
      >
        {!isMobile &&
          <span className="tooltip">{$t(t_key.tooltip.change_line_spacing)}</span>
        }
        <img className="change-line-height-img" alt={$t(t_key.tooltip.change_line_spacing)}/>
      </div>
      <div className="interface-control" 
        role="button"
        onClick={toggleWordSpacing}
        tabIndex={0}
        onKeyDown={(e) => e.key === "Enter" && toggleWordSpacing()}
      >
        {!isMobile &&
          <span className="tooltip">{$t(t_key.tooltip.change_word_spacing)}</span>
        }
        <img className="toggle-word-spacing-img" alt={$t(t_key.tooltip.change_word_spacing)}/>
      </div>
      <div className="interface-control"
        role="button" 
        onClick={increaseFont}
        tabIndex={0}
        onKeyDown={(e) => e.key === "Enter" && increaseFont()}
      >
        {!isMobile &&
          <span className="tooltip">{$t(t_key.tooltip.increase_font)}</span>
        }
        <img className="increase-font-img" alt={$t(t_key.tooltip.increase_font)}/>
      </div>
      <div className="interface-control" 
        role="button"
        onClick={decreaseFont}
        tabIndex={0}
        onKeyDown={(e) => e.key === "Enter" && decreaseFont()}
      >
        {!isMobile &&
          <span className="tooltip">{$t(t_key.tooltip.decrease_font)}</span>
        }
        <img className="decrease-font-img" alt={$t(t_key.tooltip.decrease_font)} />
      </div>
      <div className="interface-control" role="button"
        onClick={resetSettings}
        tabIndex={0}
        onKeyDown={(e) => e.key === "Enter" && resetSettings()}
      >
        {!isMobile &&
          <span className="tooltip">{$t(t_key.tooltip.reset_settings)}</span>
        }
        <img className="reset-settings-img" alt={$t(t_key.tooltip.reset_settings)}/>
      </div>
    </div>
  )
}
