import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Row, Form, Alert } from "react-bootstrap";
import { RegistrationData } from "../models/registration_data";
import { SessionInfo } from "../models/session_info";
import { $t, t_key } from "../plugins/i18n";
import { RegisterService } from "../services/register-service";
import { useTranslation } from 'react-i18next';
import { UserData } from "../models/user_data";
import { createProfileData } from "../models/profile_data";
import { showError } from "../plugins/validation";
import SigningMethodModal from "components/modals/signing/signing-method-modal";
import { ModalProviderContext } from "components/modals/modal-provider";
import ServiceInfoRow from "../components/register/service-info-row";
import ConfirmTermsFormPart from "../components/register/confirm-terms-form-part";
import { useNavigate } from "react-router";
import ErrorModal from "../components/error-modal";
import { ChangePasswordFormPart, passwordValidation } from "../components/register/change-password-form-part";
import SignTermsModal from "../components/register/sign-terms-modal";
import InfoCircleIcon from "../components/icons/info-circle-icon";
import ProfileComponent from "components/profile";
import { SpecialRightsFormPart }  from "components/special-rights/special-rights-form-part";
import Constants from "Constants";
import { fileToBase64 } from "utils/file-utils";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormField } from "components/fields/FormField";
import { ServiceRegFieldName } from "models/application_info";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ParentFormPart from "components/register/parent-form-part";
import { fieldValuesFields, fieldValuesValidationSchema } from "utils/validation-utils";
import { getBirthdayFromPersonCode, validatePersonCode } from "plugins/person-code-helper";
import useIsMobile from "plugins/breakpoint";
import BackendConfig from "backend-config";

export type RegistrationPageProps = {
  session: SessionInfo,
  registerUnderage?: boolean,
  signTerms?: boolean
}

const RegistrationPage = (props: RegistrationPageProps) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState<RegistrationData | null>(null);
  const [errorModal, setErrorModal] = React.useState<{ [key: string]: any }>({ open: false, message: "" });
  const { showModal } = useContext(ModalProviderContext);
  const { i18n } = useTranslation();
  const [signTermsModal, setSignTermsModal] = React.useState<boolean>(false);
  const [timeoutCounter, setTimeoutCounter] = React.useState<string>('');
  const [timeoutId, setTimeoutId] = React.useState<any>();
  const [specialRightsFiles, setSpecialRightsFiles] = React.useState<File[] | null>(null);

  const validationSchema = yup.object().shape({
    ...passwordValidation,
    requires_parent_approval: yup.boolean(),
    account_exists: yup.boolean(),
    is_foreign: yup.boolean(),
    app_id: yup.string(),
    use_password: yup.boolean(),
    request_special_rights: yup.boolean(),
    repeat_password: yup.string().when(["use_password"], {
      is: (use_password: boolean) => use_password,
      then: (s) => s.oneOf([yup.ref("password")], $t(t_key.validation.passwords_dont_match))
        .required($t(t_key.validation.required_field))
    }),
    parent_first_name: yup.string().when(["requires_parent_approval"], {
      is: (requires_parent_approval: boolean) => {
        return requires_parent_approval
      },
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    parent_last_name: yup.string().when(["requires_parent_approval"], {
      is: (requires_parent_approval: boolean) => {
        return requires_parent_approval
      },
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    parent_email: yup.string().email($t(t_key.validation.invalid_email)).when(["requires_parent_approval"], {
      is: (requires_parent_approval: boolean) => {
        return requires_parent_approval || props.registerUnderage
      },
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    parent_person_code: yup.string().test("validate", $t(t_key.validation.invalid_person_code),
      function(value) {
        if (value && !props.registerUnderage) {
          return validatePersonCode(value)
        }
        return true;
      }
    ),
    special_rights_files: yup.mixed().when(["request_special_rights", "requires_parent_approval"], {
      is: (request_rights: boolean, requires_parent_approval: boolean) => {
        return request_rights && !requires_parent_approval
      },
      then: (s) => s.test("hasFiles", $t(t_key.validation.special_rights_file_missing), 
        function() {
          if (specialRightsFiles && specialRightsFiles?.length > 0) {
            return true;
          }
          return false;
        }
      )
    }),
    confirm_privacy_terms: yup.boolean().oneOf([true], $t(t_key.validation.required_field)),
    confirm_service_terms: yup.boolean().oneOf([true], $t(t_key.validation.required_field)),
    is_foreign_checked: yup.boolean(),
    foreign_street: yup.string().when(["is_foreign_checked"], {
      is: (foreign_checked: boolean) => foreign_checked,
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    foreign_city: yup.string().when(["is_foreign_checked"], {
      is: (foreign_checked: boolean) => foreign_checked,
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    foreign_municipality: yup.string().when(["is_foreign_checked"], {
      is: (foreign_checked: boolean) => foreign_checked,
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    foreign_county: yup.string().when(["is_foreign_checked"], {
      is: (foreign_checked: boolean) => foreign_checked,
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    field_values: yup.object().shape({
      ...fieldValuesValidationSchema(props.session, props.registerUnderage),
      person_code: yup.string().test("validate", $t(t_key.validation.invalid_person_code),
        function(value) {
          if (value && props.registerUnderage) {
            return validatePersonCode(value)
          }
          return true;
        }
      ).when([], {
        is: () => {
          const professionField = props.session.application?.serviceRegFields.find(x => x.name === ServiceRegFieldName.PersonCode);
          if (props.registerUnderage === true) {
            return true;
          }
          return professionField?.required;
        },
        then: (s) => s.required($t(t_key.validation.required_field)),
        otherwise: (s) => s.notRequired()
      }),
    })
  })

  const methods = useForm({
    mode:"all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      is_foreign: false,
      app_id: "",
      account_exists: false,
      use_password: false,
      request_special_rights: false,
      password: "",
      repeat_password: "",
      parent_last_name: "",
      parent_first_name: "",
      parent_email: "",
      special_rights_files: [],
      parent_person_code: "",
      confirm_privacy_terms: false,
      confirm_service_terms: false,
      is_foreign_checked: false,
      foreign_street: "",
      foreign_city: "",
      foreign_municipality: "",
      foreign_county: "",
      requires_parent_approval: false,
      ...fieldValuesFields(),
    },
  });

  const RegisterTimeoutKey = 'register_countdown';
  const RegisterTimeoutUrl = 'register_redirect_url';

  const doRedirect = () => {
    const redirectUrl = sessionStorage.getItem(RegisterTimeoutUrl);
    sessionStorage.removeItem(RegisterTimeoutUrl);
    sessionStorage.removeItem(RegisterTimeoutKey);    

    if (props.registerUnderage) {
        navigator.sendBeacon(BackendConfig.Url() + "/auth/logout/force", JSON.stringify({
        returnUrl: redirectUrl
      }))
    }

    if (redirectUrl)
      window.location.href = redirectUrl;
    else
      window.location.href = window.location.href.replace(window.location.pathname, '');
  };

  const timeoutOrRedirect = (register_timeout: number | null) => {
    const existing = sessionStorage.getItem(RegisterTimeoutKey);
    let timeout = 0;

    if (register_timeout != null)
      timeout = register_timeout - 60;//redirect 1 minute before end 

    if (existing) {
      timeout = Number(existing);
    }

    const minutesLeft = timeout / 60;
    const secondsRemainder = timeout % 60;

    setTimeoutCounter(Math.trunc(minutesLeft).toString().padStart(2, '0') + ':' + secondsRemainder.toString().padStart(2, '0'));
    sessionStorage.setItem(RegisterTimeoutKey, (timeout - 1).toString());
    if (minutesLeft < 1 && secondsRemainder <= 1) {
      doRedirect();
      return;
    }

    const _timeoutId = window.setTimeout(() => { timeoutOrRedirect(null); }, 1000);
    setTimeoutId(_timeoutId);
  }

  const setCounter = (userData: UserData | null) => {
    if (userData) {
      if (userData.register_timeout_seconds > 0) {
        sessionStorage.setItem(RegisterTimeoutUrl, userData.register_timeout_redirecturl)
        timeoutOrRedirect(userData.register_timeout_seconds);
      }
    }
  }

  const loadPage = async () => {
    let userData: UserData | null = null;
    if (props.session.application != null) {
      userData = await RegisterService.fetchUserData(props.session.application.appId, null, null, true);
      if (!userData.can_register || ((props.session.soft_login == undefined || props.session.soft_login) && !props.signTerms)) {
        navigate(
          props.registerUnderage ?
            `/services/${props.session.application?.appId}/register_underage/login?strong=1` :
            `/?app_id=${props.session.application?.appId}?strong=1`
        );
        return;
      }
    }
    setCounter(userData);
    
    let data = await createProfileData(
      props.session.application ?? null,
      props.session.session?.person_code ?? "",
      props.session.session?.local_gov ?? userData?.field_values["local_gov"],
      userData,
      props.registerUnderage ?? false,
    ) as RegistrationData;
    data.requires_parent_approval = userData?.requires_parent_approval ?? false;
    data.is_foreign = userData?.is_foreign;

    setFormData(data);

    if (props.signTerms) {
      setSignTermsModal(true);
    }
  }

  const finishRegistrationNoSign = (data: any) => {
    RegisterService.finishRegisterNoSign(data.reqKey, i18n.language, data.fileKey).then(() => {
      if (props.registerUnderage) {
        navigator.sendBeacon(BackendConfig.Url() + "/auth/logout/force", JSON.stringify({
          returnUrl: "www.mirko.ee"
        }))
      }
    });
  }

  const cancelClick = () => {
    window.clearTimeout(timeoutId);
    doRedirect();
  };

  const mapDataToFormFields = () => {
    if (!formData) return;

    props.session.application?.serviceRegFields.map(x => {
      var value = formData.field_values[x.name];
      switch (x.name) {
        case ServiceRegFieldName.Birthday: {
          methods.setValue("field_values.birthday", value)
          break;
        }
        case ServiceRegFieldName.Address: {
          methods.setValue("field_values.address", value)
          break;
        }
        case ServiceRegFieldName.Email: {
          methods.setValue("field_values.email", value)
          break;
        }
        case ServiceRegFieldName.MobilePhone: {
          methods.setValue("field_values.mobile_phone", value)
          break;
        }
        case ServiceRegFieldName.FirstName: {
          methods.setValue("field_values.first_name", value)
          break;
        }
        case ServiceRegFieldName.LastName: {
          methods.setValue("field_values.last_name", value)
          break;
        }
        case ServiceRegFieldName.PersonCode: {
          methods.setValue("field_values.person_code", value)
          break;
        }
        case ServiceRegFieldName.Profession: {
          var values = ""
          if (value) {
            values = value.map((x: string) => {
              return {
                label: $t(`field.profession.${x}`),
                value: x
              }
            })
          }
          methods.setValue("field_values.profession", values)
          break;
        }
        case ServiceRegFieldName.Area: {
          var values = ""
          if (value) {
            values = value.map((x: string) => {
              return {
                label: $t(`field.area.${x}`),
                value: x
              }
            })
          }
          methods.setValue("field_values.area", values);
          break;
        }
        case ServiceRegFieldName.LocalGov: {
          methods.setValue("field_values.local_gov", value);
          break;
        }
        default: {
          break;
        }
      }
    })
  }

  // note(kristen):
  // while registering a child and closing the browser/tab
  // or navigating off the page, force logout the user
  useEffect(() => {
    if (props.registerUnderage) {
      const handleUnload = () => {
        navigator.sendBeacon(BackendConfig.Url() + "/auth/logout/force")
      }

      window.addEventListener("beforeunload", handleUnload);
      return () => {
        window.removeEventListener("beforeunload", handleUnload)
      }
    }
  }, [])

  const personCodeFieldValue = methods.watch("field_values.person_code")
  useEffect(() => {
    if (!personCodeFieldValue) return;

    const birthday: string | undefined = getBirthdayFromPersonCode(personCodeFieldValue)
    if (!birthday) {
      methods.setValue("field_values.birthday",  undefined)
      return
    }
  
    const timestamp = Date.parse(birthday)
    if (!isNaN(timestamp)) {
      const parsedDate = new Date(timestamp)
      methods.setValue("field_values.birthday", parsedDate)
      return;
    }
  }, [personCodeFieldValue])

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (!formData) return

    mapDataToFormFields();
    methods.setValue("app_id", formData.app_id)
    methods.setValue("account_exists", formData.account_exists)
    methods.setValue("is_foreign", formData?.is_foreign ?? false)
    methods.setValue("requires_parent_approval", formData?.requires_parent_approval ?? false)
    methods.setValue("parent_first_name", formData.parent_first_name)
    methods.setValue("parent_last_name", formData.parent_last_name)
    methods.setValue("parent_email", formData.parent_email)
    methods.setValue("parent_person_code", formData.parent_person_code)
  }, [formData])

  useEffect(() => {
    const setFiles = async () => {
      if (specialRightsFiles) {
        let files = [];
        for (var i = 0; i < (specialRightsFiles?.length ?? 0); i++) {
          files.push({
            name: specialRightsFiles![i].name,
            data: await fileToBase64(specialRightsFiles![i]),
            size: specialRightsFiles![i].size
          });
        }
        methods.setValue("special_rights_files", files)
      }
    }
    setFiles();

  }, [specialRightsFiles])

  if (formData == null || props.session.application == null) return null;

  const onSubmit = (data: any) => {
    if (!props.session.application) return;

    RegisterService.startRegister(data, props.session.application, i18n.language)
      .then((resp: any) => {
        if (resp.error || resp.errors) {
          var errorMessage = $t(t_key.general.service_error);
          if (resp?.errors?.error) {
            errorMessage = $t(resp.errors.error)
            setErrorModal({ open: true, message: errorMessage });
            return;
          }

          if (resp?.errors?.global) {
            setErrorModal({ open: true, message: $t(resp.errors.global[0]) });
            return;
          }
        }

        if (props.session.application?.regSignature || methods.getValues("account_exists") === false) {
          showModal(
            <SigningMethodModal
              cacheKey={resp.fileCacheKey}
              data={{ reqKey: resp.requestCacheKey, application: props.session.application }}
              proceedToService={true}
              showConsentForm={props.registerUnderage}
              sessionInfo={props.session}
              identificationCode={props.registerUnderage 
                ? methods.getValues("parent_person_code") 
                : methods.getValues("field_values.person_code")
              }
              phoneNr={props.registerUnderage 
                ? "" 
                : methods.getValues("field_values.mobile_phone") 
              }
            />
          );
        } else {
          finishRegistrationNoSign({ 
            reqKey: resp.requestCacheKey,
            application: props.session.application,
            fileKey: resp.fileCacheKey 
          })
        }
      }).catch(ex => showError(ex));
  }

  const onSubmitError = (submitErrors: any) => {
    console.error(submitErrors);
  }

  const isParent = () => {
    return props.registerUnderage ?? false;
  }

  const hiddenFields = (): ServiceRegFieldName[] => {
    var hiddenFieldsDefault = [
      ServiceRegFieldName.LocalGov,
      ServiceRegFieldName.FirstName,
      ServiceRegFieldName.LastName,
      ServiceRegFieldName.PersonCode
    ]

    if (props.registerUnderage) {
      return [
        ServiceRegFieldName.LocalGov,
      ]
    }

    return hiddenFieldsDefault;
  }

  const readOnlyFields = [
    ServiceRegFieldName.Birthday
  ]

  return <React.Fragment>
    {methods.getValues("is_foreign") === true ? <Alert variant="info" tabIndex={-1} className="page-alert">
      <div className="container">
        <InfoCircleIcon />
        <span>{$t(t_key.register_view.foreign_sign_notification)}</span>
      </div>
    </Alert> 
      : null
    }

    <Container>
      { errorModal.open 
        ? <ErrorModal show={errorModal.open}
          message={errorModal.message}
          onClose={() => setErrorModal({ open: false, message: "" })}
        /> : null
      }

      {signTermsModal 
        ? (
          <SignTermsModal show={signTermsModal} 
            session={props.session} 
            onClose={() => setSignTermsModal(false)} />
        )
        : null
      }
      <FormProvider {...methods}>
        <Form 
          onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
          className="data-form"
          noValidate
        >
          <div className="mb-5">
            <h1 id={Constants.MAIN_CONTENT} tabIndex={0}>{$t(t_key.register_view.title)}</h1>
            <ServiceInfoRow session={props.session}/>
          </div>

          <ProfileComponent formData={formData}
            isParent={isParent()}
            isRegister={true} />

          <ParentFormPart 
            registerUnderAge={!!props.registerUnderage}
            requiresParentApproval={methods.getValues("requires_parent_approval") === true}
          />

          <h2 tabIndex={-1} className="mt-5">
            { props.registerUnderage 
              ? $t(t_key.register_view.child_data) 
              : $t(t_key.register_view.your_data)
            }
          </h2>

          <Row className="mt-3">
            {props.session.application?.serviceRegFields?.map(function(f) {
              if (readOnlyFields.find(x => x === f.name)) {
                return (<FormField fld={f} readOnly={true} />);
              }

              if (hiddenFields().find(x => x === f.name)) return;
              return (<FormField fld={f} isMobile={isMobile} />);
            })}
          </Row>

          { methods.getValues("is_foreign") &&
            <Row className="mt-5">
              <Col xs={12}>
                <Controller
                  control={methods.control}
                  name={"is_foreign_checked"}
                  render={({field}) => (
                    <>
                      <Form.Check
                        checked={field.value}
                        type="switch"
                        inline
                        label={$t(t_key.register_view.foreign_address)}
                        onChange={field.onChange}
                        aria-checked={field.value}
                      />

                      { field.value === true && 
                        <>
                          <Col xs={12} sm={6} md={4}>
                            <Form.Group className="mb-3" controlId="foreign_street">
                              <Form.Label>
                                {$t(t_key.register_view.foreign_street)} <span className="req-indic">*</span>
                              </Form.Label>
                              <Controller 
                                control={methods.control}
                                name={"foreign_street"}
                                render={({field}) => (
                                  <Form.Control
                                    aria-required={true}
                                    value={field.value}
                                    type="text"
                                    isValid={!methods.formState.errors.foreign_street && !!field.value}
                                    isInvalid={!!methods.formState.errors.foreign_street}
                                    aria-describedby={
                                      !!methods.formState.errors.foreign_street
                                        ? methods.formState.errors.foreign_street.message 
                                        : ""
                                    }
                                    aria-invalid={!!methods.formState.errors.foreign_street}
                                    onChange={field.onChange}
                                  />
                                )}
                              />

                              { methods.formState.errors.foreign_street && 
                                <Form.Text className="text-danger" role="alert">
                                  { methods.formState.errors.foreign_street?.message as string }
                                </Form.Text>
                              }
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="foreign_city">
                              <Form.Label>{$t(t_key.register_view.foreign_city)} <span className="req-indic">*</span></Form.Label>
                              <Controller 
                                control={methods.control}
                                name={"foreign_city"}
                                render={({field}) => (
                                  <Form.Control
                                    value={field.value}
                                    type="text"
                                    isValid={!methods.formState.errors.foreign_city && !!field.value}
                                    isInvalid={!!methods.formState.errors.foreign_city}
                                    aria-required={true}
                                    aria-describedby={
                                      !!methods.formState.errors.foreign_city
                                        ? methods.formState.errors.foreign_city.message 
                                        : ""
                                    }
                                    aria-invalid={!!methods.formState.errors.foreign_city}
                                    onChange={field.onChange}
                                  />
                                )}
                              />

                              { methods.formState.errors.foreign_city && 
                                <Form.Text className="text-danger" role="alert">
                                  { methods.formState.errors.foreign_city?.message as string }
                                </Form.Text>
                              }
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="foreign_municipality">
                              <Form.Label>
                                {$t(t_key.register_view.foreign_municipality)} <span className="req-indic">*</span>
                              </Form.Label>
                              <Controller 
                                control={methods.control}
                                name={"foreign_municipality"}
                                render={({field}) => (
                                  <Form.Control
                                    value={field.value}
                                    type="text"
                                    isValid={!methods.formState.errors.foreign_municipality && !!field.value}
                                    isInvalid={!!methods.formState.errors.foreign_municipality}
                                    aria-required={true}
                                    aria-describedby={
                                      !!methods.formState.errors.foreign_municipality
                                        ? methods.formState.errors.foreign_municipality.message 
                                        : ""
                                    }
                                    aria-invalid={!!methods.formState.errors.foreign_municipality}
                                    onChange={field.onChange}
                                  />
                                )}
                              />

                              { methods.formState.errors.foreign_municipality && 
                                <Form.Text className="text-danger" role="alert">
                                  { methods.formState.errors.foreign_municipality?.message as string }
                                </Form.Text>
                              }
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="foreign_county">
                              <Form.Label>
                                {$t(t_key.register_view.foreign_county)} <span className="req-indic">*</span>
                              </Form.Label>
                              <Controller 
                                control={methods.control}
                                name={"foreign_county"}
                                render={({field}) => (
                                  <Form.Control
                                    value={field.value}
                                    type="text"
                                    isValid={!methods.formState.errors.foreign_county && !!field.value}
                                    isInvalid={!!methods.formState.errors.foreign_county}
                                    aria-required={true}
                                    aria-describedby={
                                      !!methods.formState.errors.foreign_county
                                        ? methods.formState.errors.foreign_county.message 
                                        : ""
                                    }
                                    aria-invalid={!!methods.formState.errors.foreign_county}
                                    onChange={field.onChange}
                                  />
                                )}
                              />

                              { methods.formState.errors.foreign_county && 
                                <Form.Text className="text-danger" role="alert">
                                  { methods.formState.errors.foreign_county?.message as string }
                                </Form.Text>
                              }
                            </Form.Group>
                          </Col>
                        </>
                      }
                    </>
                  )}
                />
              </Col>
            </Row>
          }

          <Row className="mt-5 mb-2">
            <Col xs={12}>
              <Controller
                control={methods.control}
                name={"use_password"}
                render={({field}) => (
                  <>
                    <Form.Check
                      id="login_with_password"
                      checked={field.value}
                      type="switch"
                      inline
                      label={$t(t_key.profile_view.change_or_login_with_password)}
                      onChange={field.onChange}
                      aria-pressed={field.value}
                      aria-expanded={field.value}
                    />

                    {field.value &&
                      <Row className="mt-2">
                        <ChangePasswordFormPart />
                      </Row>
                    }
                  </>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Controller
                control={methods.control}
                name={"request_special_rights"}
                render={({field}) => (
                  <>
                    <Form.Check
                      id="request_special_rights_check"
                      checked={field.value}
                      type="switch"
                      inline
                      label={$t(t_key.profile_view.special_request_service_title)}
                      onChange={field.onChange}
                      aria-pressed={field.value}
                    />

                    { field.value &&
                      <SpecialRightsFormPart
                        setFiles={setSpecialRightsFiles}
                        isUnderage={methods.getValues("requires_parent_approval")}
                      />
                    }
                  </>
                )}
              />
            </Col>
          </Row>

          <ConfirmTermsFormPart 
            session={props.session} 
          />

          <Row>
            <Col xs={12} className="form-buttons mt-3">
              {$t(t_key.register_view.timeout)} <b>{timeoutCounter}</b>
              <Button variant="secondary" onClick={cancelClick}>{$t(t_key.buttons.cancel)}</Button>
              <Button variant="primary" type="submit">
                {props.session.application?.regSignature || methods.getValues("account_exists") === false ?
                  $t(t_key.buttons.confirm_and_sign) : $t(t_key.buttons.save)}
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Container>
  </React.Fragment>
}

export default RegistrationPage;

