import * as React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { $t, t_key } from "../plugins/i18n";
import { SessionInfo } from "../models/session_info";
import { useTranslation } from "react-i18next";
import { ServiceAuth } from "../models/application_info";
import LoginPasswordControl from "../components/login/login-password-control";
import { AuthService } from "../services/auth-service";
import EidasLogo from "assets/eIDAS.png";
import TaatLogo from "assets/taat-logo.png";
import { useLocation, useNavigate } from "react-router";
import { nl2br } from "../plugins/string-helpers";
import ChevronLeftIcon from "../components/icons/chevron-left-icon";
import Constants from "../Constants";

export type LoginViewProps = {
  session: SessionInfo;
  underage?: boolean;
};

type ButtonInfo = {
  action: string;
  text: string;
  icon?: JSX.Element;
  variant: string;
  iconClass: string;
};

const LoginView = (props: LoginViewProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [buttons, setButtons] = React.useState<ButtonInfo[]>([]);
  const [authType, setAuthType] = React.useState<string>("");
  const [, setReqStrong] = React.useState<boolean>(false);

  const { i18n } = useTranslation();

  const authTypeClick = (action: string) => {
    setAuthType(action);

    switch (action) {
      case "Password":
        setAuthType(authType === "Password" ? "" : "Password");
        break;
      case "MobileID":
        authorizeTara(action);
        break;
      case "SmartID":
        authorizeTara(action);
        break;
      case "IDCard":
        authorizeTara(action);
        break;
      case "eIDAS":
        authorizeTara(action);
        break;
      case "HarID":
        authorizeHarId();
        break;
      case "TAAT":
        authorizeTAAT();
        break;
      default:
        break;
    }
  };

  const authorizeTara = (authType: string) => {
    if (!props.session.application) return;

    let returnUrl = props.underage
      ? `/services/${props.session.application?.appId}/register_underage/`
      : null;

    AuthService.authorizeTara(
      authType,
      i18n.language,
      props.session.application.appId,
      returnUrl
    );
  };

  const authorizeHarId = () => {
    if (!props.session.application) return;
    AuthService.authorizeHarId(i18n.language, props.session.application.appId);
  };

  const authorizeTAAT = () => {
    if (!props.session.application) return;
    AuthService.authorizeTAAT(i18n.language, props.session.application.appId);
  };

  const registerUnderage = () => {
    if (props.session.application != null) {
      navigate(`/services/${props.session.application?.appId}/register_underage/login?strong=1`)
    }
  };

  const defaultLogin = () => {
    if (props.session.application != null) {
      window.history.go(-1)
    }
  };
  const loadData = async () => {
    if (!props.session.application) return;

    let urlParams = new URLSearchParams(location.search);
    let strong = urlParams.get("strong") === "1";
    setReqStrong(strong);

    let tmp: ButtonInfo[] = [];
    let authTypes = props.session.application.serviceAuths.map(
      (x: ServiceAuth) => x.authType
    );

    if (authTypes.indexOf("IDCard") >= 0) {
      tmp.push({
        action: "IDCard",
        text: $t($t(t_key.login_view._id_card)),
        icon: <img className="id-card-logo" alt="" />,
        variant: "link",
        iconClass: "landscape",
      });
    }
    if (authTypes.indexOf("MobileID") >= 0) {
      tmp.push({
        action: "MobileID",
        text: $t($t(t_key.login_view._mobile_id)),
        icon: <img className="mobile-id-logo" alt="" />,
        variant: "link",
        iconClass: "portrait",
      });
    }
    if (authTypes.indexOf("SmartID") >= 0) {
      tmp.push({
        action: "SmartID",
        text: $t($t(t_key.login_view._smart_id)),
        icon: <img className="smart-id-logo" alt="" />,
        variant: "link",
        iconClass: "square",
      });
    }
    if (authTypes.indexOf("eIDAS") >= 0) {
      tmp.push({
        action: "eIDAS",
        text: $t($t(t_key.login_view._eidas_id)),
        icon: <img src={EidasLogo} alt="" />,
        variant: "link",
        iconClass: "landscape",
      });
    }
    if (authTypes.indexOf("Password") >= 0 && !strong) {
      tmp.push({
        action: "Password",
        text: $t(t_key.login_view.login_with_password),
        icon: <img className="password-login-logo" alt="" />,
        variant: "link",
        iconClass: "bigger",
      });
    }
    if (authTypes.indexOf("HarID") >= 0 && !strong) {
      tmp.push({
        action: "HarID",
        text: $t(t_key.login_view.login_with_harid),
        icon: <img className="har-id-logo" alt="" />,
        variant: "link",
        iconClass: "bigger",
      });
    }
    if (authTypes.indexOf("TAAT") >= 0 && !strong) {
      tmp.push({
        action: "TAAT",
        text: $t(t_key.login_view.login_with_taat),
        icon: <img src={TaatLogo} alt="" />,
        variant: "link",
        iconClass: "bigger",
      });
    }

    setButtons(tmp);
  };

  React.useEffect(() => {
    loadData();
  }, [location]);

  return (
    <Row>
      <Col xs={12} md={6} lg={6} className="col1">
        {props.underage ? (
          <div className="back-bttn">
            <Button variant="link" onClick={defaultLogin}>
              <ChevronLeftIcon /> <span>{$t(t_key.buttons.back)}</span>
            </Button>
          </div>
        ) : null}
        <h1>
          {$t(
            props.underage
              ? props.session.application?.type == Constants.SERVICE_TYPE_RARA
                ? t_key.login_view.rara_register_for_minor_title
                : t_key.login_view.national_register_for_minor_title
              : t_key.login_view.title
          )}
        </h1>
        <p>
          {nl2br(
            $t(
              props.underage
                ? props.session.application?.type == Constants.SERVICE_TYPE_RARA
                  ? t_key.login_view.rara_register_for_minor_description
                  : t_key.login_view.national_register_for_minor_description
                : t_key.login_view.description
            )
          )}
        </p>
      </Col>
      <Col xs={12} md={6} lg={6} className="col2 d-flex flex-column gap-3">
        <div id={Constants.MAIN_CONTENT} className="d-flex gap-2 flex-wrap justify-content-around align-items-end">
          {buttons?.map((b) => (
            <>
              <Button
                className={b.icon ? "btn-icon mt-auto" : "w-100"}
                key={b.action}
                variant={b.variant}
                onClick={() => authTypeClick(b.action)}
                aria-expanded={b.action === "Password" ? (authType === "Password") : undefined}
              >
                <div className={"mt-auto m-auto " + b.iconClass}>{b.icon}</div>
                <div className="mt-auto m-auto pt-1">{b.text}</div>
              </Button>

              {authType === "Password" && b.action == "Password" &&
                <LoginPasswordControl
                  session={props.session}
                  underage={props.underage}
                />
              }
            </>
          ))}
        </div>

        <div className="w-100 border-bottom border-grey-light border-1" />

        {props.underage ? null : (
          <Row>
            <Col
              xs={12}
              className="d-flex justify-content-center align-items-center d-flex gap-2"
            >
              <span className="fw-medium">{$t(t_key.login_view.register_for_minor)}</span>
              <span className="link fw-medium span-link" onClick={registerUnderage} tabIndex={0} role="link">
                {$t(t_key.login_view.register_for_minor_link)}
              </span>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
LoginView.displayName = "LoginView"; //for chrome to show name instead of anonymous.

export default LoginView;
