import React from 'react';
import Loader from "./components/loader";
import AppStart from "./app-start";
import MainPage from "./pages/main-page";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from 'react-router';
import { SessionService } from "./services/session-service";
import { useTranslation } from 'react-i18next';
import { SessionInfo } from './models/session_info';
import TopMenu from './components/TopMenu';
import RegistrationPage from './pages/registration-page';
import LoginSuccess from './pages/login-success';
import { $t, t_key } from "./plugins/i18n";
import ProfilePage from './pages/profile-page';
import ServiceTerminatedView from './pages/service-terminated';
import ModalProvider from './components/modals/modal-provider';
import ParentApprovalView from './pages/parent-approval-view';
import { setCookie } from './plugins/cookie-helper';
import PasswordResetPage from './pages/password-reset-page';
import LoginFailedPage from './pages/login-failed-page';
import LogoutPage from './pages/logout';
import ForceLogoutPage from './pages/logout-force';
import Constants from './Constants';
import { InterfaceSettingKey } from 'interface-control-settings';
import { SpecialRightsApprovalView } from 'pages/special-rights-approval-view';
import { ThankYou } from 'pages/thank-you-page';

function App() {
  const [theme, setTheme] = React.useState<string>('rara');
  const [showMenu, setShowMenu] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [session, setSession] = React.useState<SessionInfo | null>(null);
  const { ready: translations_ready } = useTranslation();

  const getThemeName = (sessionInfo: SessionInfo | null) => {
    let design;
    
    if (sessionInfo == null || !sessionInfo.application)
    {
      const savedDesign = sessionStorage.getItem(Constants.DESIGN);
      if (savedDesign)
        design = savedDesign;
      else
        design = "rara";
    }
    else {
      if (['national', 'mirko'].indexOf(sessionInfo.application.design) >= 0)
        design = 'mirko';
      else
        design = 'rara';

      sessionStorage.setItem(Constants.DESIGN, design);
    }

    return design;
  }

  const loadPage = async () => {
    if (location.pathname === "/") {
      const defaultRedirectUrl = await SessionService.getDefaultRedirectUrl();
      if (defaultRedirectUrl) {
        window.location.href = defaultRedirectUrl;
        return;
      }
    }

    let urlParams = new URLSearchParams(location.search);
    if (location.href.indexOf("saml/login") !== -1 && urlParams.has("requestId")) {
      const reqId = urlParams.get("requestId");
      setCookie("requestId", reqId!, 1, "/");
    }

    if (location.pathname == '/cas') {
      const challenge = urlParams.get("challenge");
      if (challenge)
        setCookie("challenge", challenge!, 1, "/");
    }

    if (location.search.indexOf('app_id') != -1 || (location.href.indexOf("saml/login") !== -1 && urlParams.has("requestId")) || (location.pathname == '/cas')) {
      sessionStorage.setItem(Constants.URL_REFERRER, document.referrer);
    }

    let sessionInfo: any = null;
    if (urlParams.has("access_token")) {
      const at = urlParams.get("access_token");
      if (at) {
        sessionInfo = await SessionService.getSessionWithOidcAccesstoken(at);
        if (sessionInfo?.session == null) {
          alert("Sessiooni ei leitud");
          if (urlParams.has("post_logout_redirect_uri")) {
            const url = urlParams.get("post_logout_redirect_uri");
            if (url)
              window.location.href = url;//used in mobile app
          }
        }
      }
      else
        sessionInfo = await SessionService.getSession();
    } else {
      sessionInfo = await SessionService.getSession();
    }

    if (sessionInfo?.languages.length > 0) {
      sessionStorage.setItem(Constants.LANGUAGES, sessionInfo.languages.join());
    } else if (sessionInfo) {
      const langs = sessionStorage.getItem(Constants.LANGUAGES);
      if (langs)
        sessionInfo.languages = langs.split(",");
    }
    setPageTheme(sessionInfo);    

    setSession(sessionInfo);

    setLoading(false);

    setPageTitle();

  };

  const loadInterfaceSettingsFromLocalStorage = () => {
    const increaseLineHeight = localStorage.getItem(InterfaceSettingKey.IS_INCREASED_LINE_HEIGHT);
    if (increaseLineHeight) {
      document.body.classList.add(increaseLineHeight);
    }

    const increasedWordSpacing = localStorage.getItem(InterfaceSettingKey.IS_INCREASED_WORD_SPACING);
    if (increasedWordSpacing) {
      document.body.classList.add(increasedWordSpacing);
    }

    const fontSize = localStorage.getItem(InterfaceSettingKey.FONT_SIZE);
    if (fontSize) {
      document.documentElement.style.fontSize = `${fontSize}%`;
    }
  }

  const setPageTitle = () => {
    if (process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "test") {
      document.title = document.title + process.env.REACT_APP_ENV.toUpperCase();
    }
  };

  const setPageTheme = (sessionInfo: any) => {
    let theme = getThemeName(sessionInfo);
    setTheme(theme);

    const highContrastTheme = localStorage.getItem(InterfaceSettingKey.IS_HIGH_CONTRAST);
    if (highContrastTheme) { 
      document.body.classList.add(highContrastTheme);
    } else {
      document.body.classList.add("theme-" + theme);
    }
    setFaviconFromTheme(theme);
  };

  const setFaviconFromTheme = (themeName: string) => {
    switch(themeName) {
      case "rara":
        setFavicon("favicon-rara.ico")
        break;
      case "mirko":
        setFavicon("favicon-mirko.ico")
        break;
      default:
        break;
    }
  };

  const setFavicon = (href: string) => {
    const head = document.querySelector('head');
    const existingFavicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  
    if (existingFavicon) {
      existingFavicon.href = href;
    } else {
      const newFavicon = document.createElement('link');
      newFavicon.rel = 'icon';
      newFavicon.href = '/'+href;
      newFavicon.type = 'image/x-icon';
      head!.appendChild(newFavicon);
    }
  };

  React.useEffect(() => {
    const show = window.location.href.indexOf("/logout/force") == -1;
    setShowMenu(show);
    loadPage();

    loadInterfaceSettingsFromLocalStorage();
  }, []);

  if (loading || !translations_ready || session == null) return null;
  return (
    <div className="App">
      <AppStart />
      {showMenu && <Loader /> }
      <BrowserRouter>
        <div className="page-content">
          <ModalProvider>
            <div className="main-page">
              {showMenu && <TopMenu theme={theme} languages={session.languages} />}
              <Routes>                
                <Route path="/" element={<MainPage session={session} />} />
                <Route path="/logout/force" element={<ForceLogoutPage session={session} />} />
                <Route path="/cas/logout" element={<LogoutPage session={session} />} />
                <Route path="/oidc/logout" element={<LogoutPage session={session} />} />
                <Route path="/saml/logout" element={<LogoutPage session={session} />} />
                <Route path="/oidc/:lng/endsession" element={<LogoutPage session={session} />} />
                <Route path="/oidc/endsession" element={<LogoutPage session={session} />} />
                <Route path="/cas" element={<MainPage session={session} />} />
                <Route path="/oidc" element={<MainPage session={session} />} />
                <Route path="/saml/login" element={<MainPage session={session} />} />
                <Route path="/saml/login/success" element={<LoginSuccess session={session} message={$t(t_key.tara_login_success_view.message)} />} />
                <Route path="/tara/login/success" element={<LoginSuccess session={session} message={$t(t_key.tara_login_success_view.message)} />} />
                <Route path="/harid/login/success" element={<LoginSuccess session={session} message={$t(t_key.harid_login_success_view.message)} />} />
                <Route path="/taat/login/success" element={<LoginSuccess session={session} message={$t(t_key.taat_login_success_view.message)} />} />
                <Route path="services/:app_id/register" element={<RegistrationPage session={session} registerUnderage={false} />} />
                <Route path="services/:app_id/register_underage/login" element={<MainPage session={session} underage={true} />} />
                <Route path="services/:app_id/register_underage" element={<RegistrationPage session={session} registerUnderage={true} />} />
                <Route path="services/:app_id/register_sign" element={<RegistrationPage session={session} signTerms={true} />} />
                <Route path="services/:app_id/profile" element={<ProfilePage session={session} />} />
                <Route path="services/:app_id/terminated" element={<ServiceTerminatedView session={session} />} />
                <Route path="services/:app_id/approval/:access_hash" element={<ParentApprovalView session={session} />} />
                <Route path="services/:app_id/login_failed" element={<LoginFailedPage session={session} enableRetry={true} />} />
                <Route path="login_failed" element={<LoginFailedPage session={session} enableRetry={false} />} />
                <Route path="password_reset/:access_hash" element={<PasswordResetPage session={session} />} />
                <Route path="services/:appId/special-rights/approval/:dependantPersonCode/:accessHash" 
                  element={<SpecialRightsApprovalView session={session}/>}
                />
                <Route path="thank-you" 
                  element={<ThankYou />}
                />
              </Routes>
              </div>
            </ModalProvider>
          </div>
        </BrowserRouter>
      </div>
  );
}

export default App;
