import { Col, Row } from "react-bootstrap";

export type SuccessViewProps = {
    message: string,
    description: string
}

const SuccessView = (props: SuccessViewProps) => {
    return (<Row>
        <Col xs={12} md={6} lg={7} xl={8} className="col1">
            <h1 tabIndex={0}>{props.message}</h1>
            <p tabIndex={0}>{props.description}</p>
        </Col>
        <Col xs={12} md={6} lg={5} xl={4} className="col2">
            <div className="d-grid gap-4">
            </div>
        </Col>
    </Row>);
}
SuccessView.displayName = "SuccessView"; //for chrome to show name instead of anonymous.

export default SuccessView;
