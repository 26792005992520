import * as React from "react";
import { Alert, Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import InfoCircleIcon from "../components/icons/info-circle-icon";
import { SessionInfo } from "../models/session_info";
import ErrorView from "../pages/error-view";
import LoginView from "../pages/login-view";
import { $t, t_key } from "../plugins/i18n";

export type MainPageProps = {
  session: SessionInfo,
  underage?: boolean
}

const MainPage = (props: MainPageProps) => {
  const { i18n } = useTranslation();

  const hasActiveSession = !!props.session.do_sso_redirect && !!props.session.redirectUrl;
  if (hasActiveSession) {
    window.location.href = props.session.redirectUrl!;
  }

  let notif = null;  
  if (props.session?.application?.systemNotificationEnabled) {
    if (props.session.application.systemNotification) {
      try {
        let dict = JSON.parse(props.session.application.systemNotification);
        if (dict && dict[i18n.language]) {
          notif = dict[i18n.language];
        }
      }
      catch { }
    }
  }
  let infoNotif = null;
  if (props.session?.application?.infoNotificationEnabled) {
    if (props.session.application.infoNotification) {
      try {
        let dict = JSON.parse(props.session.application.infoNotification);
        if (dict && dict[i18n.language]) {
          infoNotif = dict[i18n.language];
        }
      }
      catch { }
    }
  }
  return (
    <React.Fragment>
      {props.session != null && props.session.application && infoNotif &&
        <Alert variant={"info"} tabIndex={-1} className="page-alert">
          <div className="container">
            <InfoCircleIcon />
            <span>{infoNotif}</span>
          </div>
        </Alert> 
      }

      {notif &&
        <Alert variant={"warning"} tabIndex={-1} className="page-alert">
          <div className="container">
            <InfoCircleIcon />
            <span>{notif}</span>
          </div>
        </Alert> 
      }

      {props.session?.tara_notifications &&
        props.session?.tara_notifications.map((x, i) => 
          <Alert variant={"warning"} tabIndex={-1} className="page-alert" key={i}>
            <div className="container">
            <InfoCircleIcon />
            <span>{$t(x)}</span>
          </div>
          </Alert>)
      }

      <Container className="content-area">
        {
          !props.session.application 
            ? <ErrorView message={$t(t_key.login_view.unknown_service)} description="" />
            : <LoginView session={props.session} underage={props.underage} />
        }
      </Container>
    </React.Fragment>
  );
}

MainPage.displayName = "MainPage"; //for chrome to show name instead of anonymous.

export default MainPage;
