import React, { createContext, useState } from 'react';
 
interface ModalProviderContextProps {
  showModal: (modalToShow: React.ReactNode) => void;
  hideModal: () => void;
}
 
export const ModalProviderContext = createContext({} as ModalProviderContextProps);
 
interface ModalProviderProps {
  children: React.ReactNode;
}
 
const ModalProvider = ({ children }: ModalProviderProps) => {
  const [currentModal, setCurrentModal] = useState<React.ReactNode | undefined>();
 
  const showModal = (modalToShow: React.ReactNode) => {
    setCurrentModal(modalToShow);
  };
 
  const hideModal = () => {
    setCurrentModal(undefined);
  };
 
  return (
    <ModalProviderContext.Provider value={{ showModal, hideModal }}>
      {children} {currentModal}
    </ModalProviderContext.Provider>
  );
};
 
export default ModalProvider;
