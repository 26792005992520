import { SessionInfo } from "../../models/session_info";
import { $t, t_key } from "../../plugins/i18n";

export type ServiceInfoRowProps = {
  session: SessionInfo,
}

const ServiceInfoRow = (props: ServiceInfoRowProps) => {
  const text = `${$t(t_key.register_view.service)}: ${props.session.application === null ? "-" : props.session.application.name}`
  return (
    <>
      <span style={{display: "none"}} id="service-label">
        {text}
      </span>
      <span aria-labelledby="service-label" tabIndex={-1} style={{fontSize: "2rem"}}>
        <div aria-hidden>
          <b>{$t(t_key.register_view.service)}</b>:&nbsp;
          <span className="lighter">{props.session.application == null ? "-" : props.session.application.name}</span>
        </div>
      </span>
    </>
  )
}

export default ServiceInfoRow;
