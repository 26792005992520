import { Col, Row, Button, Modal } from "react-bootstrap";
import { $t, t_key } from "../../plugins/i18n";
import { useNavigate } from "react-router";
import { SessionInfo } from "../../models/session_info";
import { setCookie } from "../../plugins/cookie-helper";

type RegisterModalProps = {
	show: boolean,
	onClose: () => void,
	//app: ApplicationInfo
	session: SessionInfo
}

const RegisterModal = (props: RegisterModalProps) => {
	const navigate = useNavigate();

	const register = () => {
		if (props.session.application == null)
			return;
		navigate(`/services/${props.session.application.appId}/register`);
	}

	const login = () => {
		if (props.session.application == null)
			return;
		//setCookie("challenge", "", 1, null);
		navigate(`/?app_id=${props.session.application?.appId}&strong=1`);
	}

	if (props.session.application == null)
		return null;

	const formData = (() => {
		if (!props.session.application.regEnabled) {
			return {
				title: $t(t_key.register_modal.title_no_reg),
				details: $t(t_key.register_modal.description_no_reg),
				show_registration: false,
				show_login: true
			}
		}
		if (!props.session.session?.can_register) {
			return {
				title: $t(t_key.register_modal.title_underage),
				details: $t(t_key.register_modal.description_underage),
				show_registration: false,
				show_login: true
			}
		}
		if (props.session.session?.requires_parent_approval) {
			return {
				title: $t(t_key.register_modal.title_approval),
				details: $t(t_key.register_modal.description_approval),
				details2: props.session.soft_login ? (t_key.register_modal.strong_auth_is_required) : null,
				show_registration: !props.session.soft_login,
				show_login: false,
				show_strong_login: props.session.soft_login
			}
		}
		return {
			title: $t(t_key.register_modal.title),
			details: $t(t_key.register_modal.description),
			details2: props.session.soft_login ? (t_key.register_modal.strong_auth_is_required) : null, 
			show_registration: !props.session.soft_login,
			show_login: false,
			show_strong_login: props.session.soft_login
		}

	})();

	return <Modal show={props.show} onHide={props.onClose} className="ra-modal" size="lg">
		<Modal.Header closeButton>
			<Modal.Title>{formData.title}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row className="mb-3">
				<Col xs={12}>
					<div>{formData.details}</div>
				</Col>
				{formData.details2 ? <Col xs={12} className="mt-3">
					<div>{formData.details2}</div>
				</Col> : null}
			</Row>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={props.onClose}>
				{$t(t_key.buttons.cancel)}
			</Button>
			{formData.show_registration ? <Button variant="primary" onClick={register}>
				{$t(t_key.buttons.register_account)}
			</Button> : null}
			{formData.show_login ? <Button variant="primary" onClick={login}>
				{$t(t_key.buttons.login_with_another_account)}
			</Button> : null}
			{formData.show_strong_login ? <Button variant="primary" onClick={login}>
				{$t(t_key.buttons.login_with_strong_auth)}
			</Button> : null}
		</Modal.Footer>
	</Modal>;
}

export default RegisterModal;
