
export const MapToSierraAddress = (addressJson: any) => {
    if (!addressJson) return null;

    let county = addressJson.maakond ?? "";
    let parish = addressJson.omavalitsus ?? "";
    let city = !addressJson.asustusyksus ? (addressJson.vaikekoht ?? "") : addressJson.asustusyksus;
    let street = !addressJson.aadresstekst ? (addressJson.nimi ?? "") : addressJson.aadresstekst;
    if (street && addressJson.kort_nr) {
        street += "-" + addressJson.kort_nr;
	}

    return `${street}$${city}$${parish}$${county}$Eesti`;
};

export const MapFromSierraAddress = async (address?: string | undefined) => {
    if (!address) return null;
    const parts = address.split('$');
    const addressText = parts.slice(0, parts.length - 2);

    const result = await fetch(
        `https://inaadress.maaamet.ee/inaadress/gazetteer?appartment=1&address=${encodeURIComponent(
            addressText.join(', ')
        )}`
    );
    const resp = await result.clone().json();
    return resp.addresses.length > 0 ? resp.addresses[0] : null;
};
