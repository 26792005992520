import BackendApi from "backend-api";
import BackendConfig from "backend-config";
import i18n, { $t, t_key } from "plugins/i18n";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { ModalProviderContext } from "../modal-provider";
import SigningSuccessModal from "./signing-success-modal";
import hwcryptomodule from "assets/js/hwcrypto";
import SigningMethodModal from "./signing-method-modal";
import ErrorModal from "../error-modal";
import { RegisterService } from "services/register-service";
import { RedirectResponse } from "models/redirect_response";

type SigningWaitingProps = {
  signingMethod: string;
  identificationCode: string;
  phoneNr: string;
  cacheKey: string;
  data?: any;
  onFinish?: (data: any, containerId: string) => void;
  onClose?: () => void;
  proceedToService?: boolean;
  isUnRegister?: boolean;
  redirectUrl?: string;
}

interface DigitalSignatureStateCommon {
  challengeID?: string;
  containerID?: string;
  generatedSignatureId?: string;
  tempFilesCacheKeys?: string[];
}

interface MobilePingResult {
  ok: boolean;
  data: string;
  viga: string;
}

interface IdCardSignatureParameters {
  hashHex?: string;
  digestAlgorithm?: string;
  error?: string;
  state?: DigitalSignatureStateCommon;
}

let timeout: number;

const SigningWaitingModal = (props: SigningWaitingProps) => {
  const [redirectLink, setRedirectLink] = useState<string | undefined>(undefined);
  const [challengeId, setChallengeId] = React.useState<string>();
  const [containerId, setContainerId] = useState<string | null>(null);

  const { hideModal, showModal } = useContext(ModalProviderContext);

  const finishRegistration = (data: any, containerId: string) => {
    RegisterService.finishRegister(data.reqKey, i18n.language, containerId)
      .then((resp) => {
        setRedirectLink((resp as RedirectResponse).redirectUrl);
      });
  }

  const ping = (mobileSigningState: DigitalSignatureStateCommon) => {
    let queryParams = new URLSearchParams();
    queryParams.set("isMobileId", props.signingMethod === "mid" ? "true" : "false");
    queryParams.set("signatureId", mobileSigningState.generatedSignatureId ?? "");
    queryParams.set("containerId", mobileSigningState.containerID ?? "");
    const url = BackendConfig.Url() + "/siga/pingMobile?" + queryParams.toString();
    BackendApi.get(url, true).then((pingMobileResult: MobilePingResult) => {
      if (pingMobileResult.ok) {
        BackendApi.post(BackendConfig.Url() + "/siga/finishMobile", { containerId: mobileSigningState.containerID, tempFilesCacheKeys: mobileSigningState.tempFilesCacheKeys }, true)
          .then(finishMobileResult => {
            if (finishMobileResult?.error) {
              handleError(finishMobileResult?.error);
            } else {
              setContainerId(finishMobileResult)
            }

          })
          .catch(error => handleError(error?.error ?? error));
      }
      else if (pingMobileResult.viga) {
        handleError(pingMobileResult.data);
      }
      else {
        // setLastAttempt(
        //     `${signingMethod === 'mid' ? 'Mobiil ID' : 'Smart ID'} PIN2 sisestatuse viimane kontroll ${new Date(Date.now()).toLocaleTimeString('et-EE')}`);
        timeout = window.setTimeout(() => ping(mobileSigningState), 2000);
      }
    })
  }

  const array2hex = (args: string | any[]) => {
    let ret = '';
    for (let i = 0; i < args.length; i++)
      ret += (args[i] < 16 ? '0' : '') + args[i].toString(16);
    return ret.toLowerCase();
  };

  const startMobile = () => {
    setChallengeId(undefined);

    if (props.signingMethod === "mid" && !props.phoneNr && !props.identificationCode) {
      return;
    }

    if (props.signingMethod === "smartid" && !props.identificationCode) {
      return;
    }

    let queryParams = new URLSearchParams();
    queryParams.set("isikukood", props.identificationCode ?? "");
    queryParams.set("telephoneNr", (props.signingMethod === "mid" ? props.phoneNr : null) ?? "");
    queryParams.set("cacheKey", props.cacheKey);

    const startUrl = props.signingMethod === "mid" ? "/siga/startMobileId?" : "/siga/startSmartId?";
    BackendApi.post(BackendConfig.Url() + startUrl + queryParams.toString(), null, true)
      .then((startMobileResult: DigitalSignatureStateCommon) => {
        const error = (startMobileResult as any)?.error;
        if (error) {
          handleError(error?.error ?? error);
          return;
        }
        setChallengeId(startMobileResult.challengeID);
        timeout = window.setTimeout(() => ping(startMobileResult), 2000);
      }).catch(error => {
        handleError(error?.error ?? error);
      });
  }
  const validationError = $t(t_key.validation.signing_error);
  const handleError = (message: string) => {
    window.clearTimeout(timeout);
    
    message = message.replace("{signing_error}", validationError);
    showModal(<ErrorModal title={$t(t_key.signing_method_modal.error_during_signing)} content={message} nextModal={
      <SigningMethodModal 
        cacheKey={props.cacheKey}
        data={props.data}
        onFinish={props.onFinish}
        proceedToService={props.proceedToService}
        identificationCode={props.identificationCode}
        phoneNr={props.phoneNr} />
    } />)
  }

  const handleHwCryptoError = (error: any) => {
    const message = {
      user_cancel: 'Kasutaja katkestas allkirjastamise.',
      no_certificates:
        'ID-kaarti ei leitud. Palun veenduga, et ID-kaart on töökorras ja korrektselt lugejasse sisestatud.',
      no_implementation:
        'ID-kaardiga sisselogimine ei ole toetatud kasutatavas veebilehitsejas või puudub vastav tarkvara.',
      pin_blocked:
        'ID-kaarti ei ole võimalik kasutada, kuna PIN koodid on blokeeritud.',
    }[error.message as string];
    handleError(message as string);
  };

  const startId = () => {
    hwcryptomodule()
      .getCertificate({ lang: "et" })
      .then(certificate => {
        let queryParams = new URLSearchParams();
        queryParams.set("hex", array2hex(certificate.encoded));
        queryParams.set("language", "EST");
        queryParams.set("cacheKey", props.cacheKey);
        BackendApi.post(BackendConfig.Url() + "/siGa/startIdCard?" + queryParams.toString())
          .then((idCardSigningState: IdCardSignatureParameters) => {
            hwcryptomodule()
              .sign(certificate,
                {
                  type: idCardSigningState.digestAlgorithm,
                  hex: idCardSigningState.hashHex
                },
                { lang: "et" })
              .then(signature => {
                BackendApi.post(BackendConfig.Url() + "/siga/finishIdCard", {
                  idCardSignatureHex: signature.hex,
                  containerId: idCardSigningState.state?.containerID,
                  tempFilesCacheKeys: idCardSigningState.state?.tempFilesCacheKeys,
                  generatedSignatureId: idCardSigningState.state?.generatedSignatureId
                })
                  .then(finishIdCardResult => {
                    if (!!finishIdCardResult.error) {
                      handleError(finishIdCardResult.error);
                      return;
                    }
                    setContainerId(finishIdCardResult)
                  })
                  .catch(error => handleError(error?.error ?? error));
              })
              .catch(error => handleHwCryptoError(error));
          })
          .catch(error => handleError(error?.error ?? error));
      })
      .catch(error => handleHwCryptoError(error));
  }

  const startSigning = () => {
    if (props.signingMethod === "mid" || props.signingMethod === "smartid") {
      startMobile();
      return;
    }
    else if (props.signingMethod === "idcard") {
      startId();
    }
  }

  const onClose = () => {
    window.clearTimeout(timeout);
    hideModal();
  }

  useEffect(() => {
    startSigning();

    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!containerId) {
      return;
    }

    window.clearTimeout(timeout);

    if (props.proceedToService) {
      finishRegistration(props.data, containerId)
    } else {
      //SSOR-222(kristen): currently only used when unregister is triggered
      if (!!props.onFinish) {
        props.onFinish(props.data, containerId)
      }

      showModal(
        <SigningSuccessModal 
          containerId={containerId}
          onClose={props.onClose}
          proceedToService={props.proceedToService}
          isUnRegister={props.isUnRegister}
        />
      );
    }
  }, [containerId])

  useEffect(() => {
    if (!props.isUnRegister && redirectLink) {
      if (containerId) {
        showModal(
          <SigningSuccessModal 
            containerId={containerId}
            onClose={props.onClose}
            proceedToService={props.proceedToService}
            isUnRegister={props.isUnRegister}
            redirectUrl={redirectLink}
          />
        );
      }
      return;
    }

  }, [finishRegistration])

  return (
    <Modal show onHide={onClose} className="ra-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Digiallkirjastamine
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.signingMethod === "mid" || props.signingMethod === "smartid" ?
          <React.Fragment>
            <p tabIndex={0}>
              Toimub allkirjastamispäringu saatmine telefonile. Veendu kontrollkoodi õiguses ja sisesta {props.signingMethod === "mid" ? "Mobiil-ID" : "Smart ID"} PIN2.
            </p>
            <p tabIndex={0}>
              Kontrollkood: {challengeId ? (<strong>{challengeId}</strong>) : "..."}
            </p>
          </React.Fragment> : null}
        <p tabIndex={0}>
          Allkirjastamise PINi sisestamise järel luuakse dokumendile digiallkiri, millest võivad allkirjastajale tuleneda
          õigustuslikud kohustused. Seetõttu pead olema veendunud, et oled allkirjastanud info sisuga nõus.
          Kahtluse korral mine tagasi ja kontrolli dokumendi sisu.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {$t(t_key.buttons.cancel)}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SigningWaitingModal;
