export const validatePersonCode = (personCode: string): boolean => {
  if (!personCode || personCode.length !== 11) return false

  const stepOneWeight: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1]
  const stepTwoWeight: number[] = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3]

  const controlNumber: number = +personCode.slice(-1)
  if (isNaN(controlNumber)) {
    console.error("Could not get the control nr; Got NaN")
    return false
  }

  var stepOne: number = 0
  for (var i = 0; i < stepOneWeight.length; ++i) {
    const nrValue: number = +personCode[i]
    if (isNaN(nrValue)) {
      console.error("could not convert string to nr; Got NaN")
      return false;
    }

    const x = stepOneWeight[i] * nrValue
    stepOne += x
  }

  const stepOneResult: number = Math.floor(stepOne % 11)
  if (stepOneResult < 10 && stepOneResult === controlNumber) {
    return true
  }
  
  var stepTwo: number = 0
  if (stepOneResult === 10) {
    for (var i = 0; i < stepTwoWeight.length; ++i) {
      const nrValue: number = +personCode[i]
      if (isNaN(nrValue)) {
        console.error("could not convert string to nr; Got NaN")
        return false;
      }

      const x = stepTwoWeight[i] * nrValue
      stepTwo += x
    }

    const stepTwoResult: number = Math.floor(stepTwo % 11)
    if (stepTwoResult < 10 && stepTwoResult === controlNumber) {
      return true
    }

    if (stepTwoResult === 10 && controlNumber === 0) {
      return true
      
    }
  }

  return false;
}

export const getBirthdayFromPersonCode = (personCode: string): string | undefined => {
  const isValidPersonCode: boolean = validatePersonCode(personCode)
  if (!isValidPersonCode) return;

  const year = personCode.substring(1, 3)
  const month = personCode.substring(3, 5)
  const day = personCode.substring(5, 7)

  const key: string = personCode[0]
  const years = [
    { key: "3", yearPrefix: "19"},
    { key: "4", yearPrefix: "19"},
    { key: "5", yearPrefix: "20"},
    { key: "6", yearPrefix: "20"}
  ]

  const yearPrefix: string | undefined = years.find(x => x.key === key)?.yearPrefix
  if (!yearPrefix) return;

  const readableYear: string = `${yearPrefix}${year}`
  return `${readableYear}-${month}-${day}`
}
