import { $t, t_key } from "plugins/i18n";
import React, { HtmlHTMLAttributes, KeyboardEventHandler, useContext, useEffect, useRef } from "react";
import { Button, CloseButton, Col, Form, Modal, Row } from "react-bootstrap"
import IdCard from "components/icons/id-card";
import MobileId from "components/icons/mobile-id";
import SmartId from "components/icons/smart-id";
import { ModalProviderContext } from "../modal-provider";
import SigningWaitingModal from "./signing-waiting-modal";
import DocumentsToSignFormPart from "../../register/documents-to-sign-form-part";
import useIsMobile from "plugins/breakpoint";
import { SessionInfo } from "models/session_info";

type SigningMethod = {
  icon: JSX.Element,
  text: string,
  value: string,
  default?: boolean
}

type SigningMethodProps = {
  phoneNr?: string,
  identificationCode?: string,
  cacheKey: string,
  data?: any,
  showConsentForm?: boolean,
  unRegistration?: boolean,
  onFinish?: (data: any, containerId: string) => void,
  onClose?: () => void,
  proceedToService?: boolean,
  redirectUrl?: string,
  sessionInfo?: SessionInfo
}

type ErrorMessage = {
  phoneNumber?: string,
}

const SigningMethodModal = (props: SigningMethodProps) => {
  const signingMethods: SigningMethod[] = [
    { icon: <IdCard />, text: $t(t_key.login_view._id_card), value: "idcard", default: true },
    { icon: <MobileId />, text: $t(t_key.login_view._mobile_id), value: "mid" },
    { icon: <SmartId />, text: $t(t_key.login_view._smart_id), value: "smartid" }
  ];

  const [signingMethod, setSigningMethod] = React.useState<string>(signingMethods.find(x => x.default)?.value!);
  const [identificationCode, setIdentificationCode] = React.useState<string>(props.identificationCode ?? "");
  const [phoneNumber, setPhoneNumber] = React.useState<string>(props.phoneNr ?? "");
  const [errorMessage, setErrorMessage] = React.useState<ErrorMessage | null>(null);
  const isMobile = useIsMobile();

  const { hideModal, showModal } = useContext(ModalProviderContext);

  const firstFocusRef = useRef<HTMLButtonElement>(null);
  const lastFocusRef = useRef<HTMLButtonElement>(null);

  // Focustrap the modal
  const onSubmitKeyDownEvent: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      firstFocusRef.current?.focus()
    }
  }

  const renderSigningMethods = (): JSX.Element => {
    const onIdCardSelected: KeyboardEventHandler<HTMLElement> = (e) => {
      if (e.key === " ") {
        e.preventDefault();
        setSigningMethod("idcard");
      }
    }

    const onMidSelected: KeyboardEventHandler<HTMLElement> = (e) => {
      if (e.key === " ") {
        e.preventDefault();
        setSigningMethod("mid");
      }
    }

    const onSmartIdSelected: KeyboardEventHandler<HTMLElement> = (e) => {
      if (e.key === " ") {
        e.preventDefault();
        setSigningMethod("smartid");
      }
    }

    return (
      <>
        <Col key={"idcard"}
          className="d-flex align-items-center justify-content-center flex-column"
          tabIndex={0}
          onKeyDown={(e) => onIdCardSelected(e)}
          role={"radio"}
          aria-checked={"idcard" === signingMethod}
        >
          <Form.Label className="d-flex align-items-center justify-content-center" htmlFor={`method_idcard`} aria-hidden={isMobile}>
            <IdCard/>
          </Form.Label>
          <Form.Check
            tabIndex={-1}
            className="mt-3"
            id={`method_idcard`}
            type="radio"
            name="method"
            value={"idcard"}
            label={$t(t_key.login_view._id_card)}
            aria-checked="true"
            checked={"idcard" == signingMethod}
            onChange={() => setSigningMethod("idcard")}
          />
        </Col>
        <Col key={"mid"}
          className="d-flex align-items-center justify-content-center flex-column"
          tabIndex={0}
          onKeyDown={(e) => onMidSelected(e)}
          role={"radio"}
          aria-checked={"mid" === signingMethod}
        >
          <Form.Label className="d-flex align-items-center justify-content-center" htmlFor={`method_mid`} aria-hidden={isMobile}>
            <MobileId />
          </Form.Label>
          <Form.Check
            tabIndex={-1}
            className="mt-3"
            id={`method_mid`}
            type="radio"
            name="method"
            value={"mid"}
            label={$t(t_key.login_view._mobile_id)}
            checked={"mid" == signingMethod}
            onChange={() => setSigningMethod("mid")}
          />
        </Col>
        <Col key={"smartid"}
          className="d-flex align-items-center justify-content-center flex-column"
          tabIndex={0}
          onKeyDown={(e) => onSmartIdSelected(e)}
          role={"radio"}
          aria-checked={"smartid" === signingMethod}
        >
          <Form.Label className="d-flex align-items-center justify-content-center" htmlFor={`method_smartid`} aria-hidden={isMobile}>
            <SmartId />
          </Form.Label>
          <Form.Check
            tabIndex={-1}
            className="mt-3"
            id={`method_smartid`}
            type="radio"
            name="method"
            value={"smartid"}
            label={$t(t_key.login_view._smart_id)}
            checked={"smartid" == signingMethod}
            onChange={() => setSigningMethod("smartid")}
          />
        </Col>
      </>
    );
  }

  return (
    <Modal 
      show
      onHide={hideModal} 
      className="ra-modal" size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          <h1 tabIndex={-1}>
            {$t(t_key.signing_method_modal.title)}
          </h1>
        </Modal.Title>
        <CloseButton 
          onClick={hideModal}
          ref={firstFocusRef}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>{$t(t_key.signing_method_modal.description)}</Col>
        </Row>

        <DocumentsToSignFormPart
          readOnly={true}
          showConsentForm={props.showConsentForm ?? false}
          unRegistration={props.unRegistration ?? false}
          generatePdf={() => ({})}
        />

        <span style={{fontSize: "2rem", fontWeight: 600}} tabIndex={-1} className="mt-3">
          {$t(t_key.signing_method_modal.choose_sign_method)}
        </span>

        <Row className="mb-3">
          { renderSigningMethods() }
        </Row>
        <Row className="mb-3" hidden={signingMethod !== "mid"}>
          <Col className="d-flex justify-content-center">
            <Form.Group className="w-50">
              <Form.Label htmlFor="mobile-phone-control" className="required">
                {$t(t_key.field.mobile_phone)} <span className="req-indic">*</span>
              </Form.Label>
              <Form.Control
                id="mobile-phone-control"
                required
                type="text"
                defaultValue={props.phoneNr}
                isInvalid={!!errorMessage?.phoneNumber}
                onChange={e => {
                  if (e.target.value) {
                    setErrorMessage({phoneNumber: undefined})
                  } else {
                    setErrorMessage({phoneNumber: $t(t_key.validation.required_field)})
                  }

                  setPhoneNumber(e.target.value)
                }}
              />

              {errorMessage?.phoneNumber &&
                <Form.Text className="text-danger" role="alert">
                  {errorMessage.phoneNumber}
                </Form.Text>
              }
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3" hidden={signingMethod !== "smartid"}>
          <Col className="d-flex justify-content-center">
            <Form.Group className="w-50">
              <Form.Label htmlFor="id-code-control" className="required">
                {$t(t_key.field.person_code)}
              </Form.Label>
              <Form.Control
                id="id-code-control"
                required
                type="text"
                maxLength={11}
                minLength={11}
                defaultValue={props.identificationCode}
                onChange={e => setIdentificationCode(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          {$t(t_key.buttons.cancel)}
        </Button>
        <Button ref={lastFocusRef}
          onKeyDown={(e) => onSubmitKeyDownEvent(e)}
          variant="primary" onClick={() => {
            setErrorMessage(null);
            if (!phoneNumber && signingMethod === "mid") {
              setErrorMessage({phoneNumber: $t(t_key.validation.required_field)})
              return;
            }

            showModal(
              <SigningWaitingModal
                signingMethod={signingMethod}
                cacheKey={props.cacheKey}
                onFinish={props.onFinish}
                identificationCode={identificationCode}
                isUnRegister={props.unRegistration}
                redirectUrl={props.redirectUrl}
                data={props.data}
                phoneNr={phoneNumber}
                proceedToService={props.proceedToService}
                onClose={props.onClose} />)
          }
          }>
          {$t(t_key.buttons.continue)}
        </Button>
      </Modal.Footer>
    </Modal>)
}

export default SigningMethodModal;
