import { Col, Row, Button, Modal } from "react-bootstrap";
import { $t, t_key } from "../../plugins/i18n";
import { SessionInfo } from "../../models/session_info";

type SignTermsModalProps = {
	show: boolean,
	session: SessionInfo,
	onClose: () => void
}

const SignTermsModal = (props: SignTermsModalProps) => {
	if (props.session.application == null)
		return null;

	return <Modal show={props.show} onHide={props.onClose} className="ra-modal" size="lg">
		<Modal.Header closeButton>
			<Modal.Title>{$t(t_key.sign_terms_modal.title)}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row className="mb-3">
				<Col xs={12}>
					<div>{$t(t_key.sign_terms_modal.description)}</div>
				</Col>
			</Row>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="primary" onClick={() => props.onClose()}>
				{$t(t_key.buttons.continue)}
			</Button>
		</Modal.Footer>
	</Modal>;
}

export default SignTermsModal;
