import { ServiceRegFieldName } from "models/application_info";
import { SessionInfo } from "models/session_info";
import { $t, t_key } from "plugins/i18n";
import { validatePersonCode } from "plugins/person-code-helper";
import { validatePhoneNumber } from "plugins/validation";
import * as yup from "yup";

export function fieldValuesFields() {
  return {
    field_values: {
      birthday: "",
      email: "",
      mobile_phone: "",
      first_name: "",
      last_name: "",
      person_code: "",
      address: "",
      area: "",
      profession: "",
      local_gov: "",
    }
  }
}

export function fieldValuesValidationSchema(session: SessionInfo, registerUnderage = false) {
  return { 
    email: yup.string().email($t(t_key.validation.invalid_email)).when([], {
      is: () => {
        const emailField = session.application?.serviceRegFields.find(x => x.name === ServiceRegFieldName.Email);
        return emailField && emailField.required
      },
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    mobile_phone: yup.string().when([], {
      is: () => {
        const phoneField = session.application?.serviceRegFields.find(x => x.name === ServiceRegFieldName.MobilePhone);
        return phoneField && phoneField.required
      },
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }).test("validNumber", $t(t_key.validation.invalid_phone_number), function(value) {
      if (value) {
        return validatePhoneNumber(value).isValid
      }
      return true;
    }),
    birthday: yup.mixed().when([], {
      is: () => {
        const birthdayField = session.application?.serviceRegFields.find(x => x.name === ServiceRegFieldName.Birthday);
        return birthdayField && birthdayField.required;
      },
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    first_name: yup.string().when([], {
      is: registerUnderage,
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    last_name: yup.string().when([], {
      is: registerUnderage,
      then: (s) => s.required($t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    address: yup.mixed().test("valid_required", $t(t_key.validation.required_field),
      function(value) {
        const addressField = session.application?.serviceRegFields.find(x => x.name === ServiceRegFieldName.Address);
        if (!addressField) {
          return true;
        }
        // @ts-ignore
        if (addressField.required && value.length === 0) {
          return false;
        }

        return true;
      }
    ),
    area: yup.mixed().test("req", $t(t_key.validation.required_field), function(value){
      const areaField = session.application?.serviceRegFields.find(x => x.name === ServiceRegFieldName.Area);
      if (!areaField) {
        return true;
      }
      // @ts-ignore
      if (areaField.required === true && value.length === 0) {
        return false;
      }
      return true;
    }),
    profession: yup.mixed().test("req", $t(t_key.validation.required_field), function(value) {
      const professionField = session.application?.serviceRegFields.find(x => x.name === ServiceRegFieldName.Profession);
      if (!professionField) {
        return true;
      }
      // @ts-ignore
      if (professionField.required === true && value.length === 0) {
        return false;
      }
      return true;
    }),
    local_gov: yup.string()
  }
}
