import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import './styles/main.scss';
import App from './App';
import './plugins/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);

