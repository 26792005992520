import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { LogoutResponse } from "../models/logout-response";
import { RedirectResponse } from "models/redirect_response";

export const LogoutService = {
  logoutSAML: async function (query: any, manualLogout:boolean): Promise<LogoutResponse> {
    const url = BackendConfig.Url() + "/saml/redirectback" + query + "&manualLogout=" + manualLogout;
    const result: LogoutResponse = await BackendApi.get(url);

    return result;
  },
  logoutCAS: async function (query: any, manualLogout: boolean): Promise<LogoutResponse> {
    const url = BackendConfig.Url() + "/cas/logout" + query + "&manualLogout=" + manualLogout;
    const result: LogoutResponse = await BackendApi.get(url);

    return result;
  },
  endSessionOIDC: async function (query: any, manualLogout: boolean): Promise<LogoutResponse> {
    const url = BackendConfig.Url() + "/oidc/endsession" + query + "&manualLogout=" + manualLogout;
    const result: LogoutResponse = await BackendApi.get(url);

    return result;
  },
  forceLogout: async function (returnUrl: string): Promise<RedirectResponse> {
    const url = BackendConfig.Url() + "/auth/logout/force?return_url=" + returnUrl;
    const result: RedirectResponse = await BackendApi.post(url);

    return result;
  }
};
