import { Col, Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { $t, t_key } from "../../plugins/i18n";
import * as yup from "yup";

type ChangePasswordFormPartProps = {
  formData?: { [key: string]: any },
  localErrors?: { [key: string]: any },
  change?(val: string, field: string): void
}

export const passwordValidation = {
  password: yup.string().when("use_password", {
    is: true,
    then: (s) => s.test({
      name: "passwordLength",
      message: $t(t_key.validation.password_requirements_length),
      test: (value) => {
        if (!value) return;
        if (value?.length < 8 || value?.length > 24) {
          return false
        }
        return true;
      }
    }).test({
        name: "containsUpperCase",
        message: $t(t_key.validation.password_requirements_one_upper),
        test: (value) => {
          if (!value) return;
          if (!/[A-Z]/.test(value)) {
            return false;
          }
          return true;
        }
      }).test({
        name: "containsLowercase",
        message: $t(t_key.validation.password_requirements_one_lower),
        test: (value) => {
          if (!value) return;
          if (!/[a-z]/.test(value)) {
            return false;
          }
          return true;
        }
      }).test({
        name: "containsNumber",
        message: $t(t_key.validation.password_requirements_one_number),
        test: (value) => {
          if (!value) return;
          if (!/\d/.test(value)) {
            return false;
          }
          return true;
        }
      }).test({
        name: "charRepetition",
        message: $t(t_key.validation.password_requirements_no_triple_char),
        test: (value) => {
          if (!value) return;
          if (/(.)\1{2,}/.test(value)) {
            return false;
          }
          return true;
        }
      }).required($t(t_key.validation.required_field))
  })
}

export const ChangePasswordFormPart = (props: ChangePasswordFormPartProps) => {
  const {control, formState: {errors}} = useFormContext();

  return (
    <>
      <span style={{display: "none"}} id={"required"}>entry required</span>
      <Col xs={12} sm={6}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="password-control" aria-describedby="required">
            {$t(t_key.register_view.password)}
            <span className="req-indic" aria-hidden>*</span>
          </Form.Label>
          <Controller
            control={control}
            name={"password"}
            render={({field}) => (
              <Form.Control
                id="password-control"
                ref={field.ref}
                type="password"
                role="textbox"
                onChange={field.onChange}
                value={field.value}
                isValid={!errors.password && !!field.value}
                isInvalid={!!errors.password}
                aria-required="true"
                aria-labelledby="required"
                aria-invalid={!!errors.password}
                aria-describedby={
                  !!errors.password 
                    ? errors.password.message as string
                    : ""
                }
              />
            )}
          />
          { errors.password && 
            <Form.Text className="text-danger" role="alert">
              { errors.password?.message as string }
            </Form.Text>
          }
        </Form.Group>
      </Col>
      <Col xs={12} sm={6}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="repeat-password-control">
            {$t(t_key.register_view.repeat_password)}
            <span className="req-indic" aria-hidden>*</span>
          </Form.Label>
          <Controller
            control={control}
            name={"repeat_password"}
            render={({field}) => (
              <Form.Control
                id="repeat-password-control"
                ref={field.ref}
                value={field.value}
                type="password"
                role="textbox"
                onChange={field.onChange}
                isValid={(!errors.repeat_password && !!field.value)}
                isInvalid={!!errors.repeat_password}
                aria-invalid={!!errors.repeat_password}
                aria-required="true"
                aria-labelledby="required"
                aria-describedby={
                  !!errors.repeat_password 
                    ? errors.repeat_password.message as string
                    : ""
                }
              />
            )}
          />
          { errors.repeat_password && 
            <Form.Text className="text-danger" role="alert">
              { errors.repeat_password?.message as string }
            </Form.Text>
          }
        </Form.Group>
      </Col>
    </>
  )
}
