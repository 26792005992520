import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Row, Col, Button, Alert } from "react-bootstrap"
import { createProfileData, ProfileData } from "../models/profile_data";
import { SessionInfo } from "../models/session_info";
import { $t, t_key } from "../plugins/i18n";
import { RegisterService } from "../services/register-service";
import { useNavigate } from "react-router";
import { showError, showErrorModal } from "../plugins/validation";
import { useTranslation } from 'react-i18next';
import ConfirmModal from "../components/confirm-modal";
import ErrorModal from "../components/error-modal";
import { ChangePasswordFormPart, passwordValidation } from "../components/register/change-password-form-part";
import SigningMethodModal from "components/modals/signing/signing-method-modal";
import { ModalProviderContext } from "components/modals/modal-provider";
import { LogoutResponse } from "../models/logout-response";
import Constants from "../Constants";
import { SpecialRightsFormPart } from "components/special-rights/special-rights-form-part";
import { fileToBase64 } from "utils/file-utils";
import ProfileComponent from "components/profile";
import { Label, LabelType } from "components/label";
import { UserData } from "models/user_data";
import { ServiceRegFieldName } from "models/application_info";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FormField } from "components/fields/FormField";
import useBreakpoint from "../plugins/breakpoint";
import classNames from "classnames";
import { fieldValuesFields, fieldValuesValidationSchema } from "utils/validation-utils";
import { validatePersonCode } from "plugins/person-code-helper";

export type ProfilePageProps = {
	session: SessionInfo
}

const ProfilePage = (props: ProfilePageProps) => {
	const navigate = useNavigate();
  const isMobile = useBreakpoint();
  const { i18n } = useTranslation();

  const [userDataResponse, setUserDataResponse] = useState<UserData | null>(null);
  const [formData, setFormData] = React.useState<ProfileData | null>(null);
	const [confirmModal, setConfirmModal] = React.useState<{ [key: string]: any }>({ open: false, title: "", message: "" });
	const [errorModal, setErrorModal] = React.useState<{ [key: string]: any }>({ open: false, message: "" });
	const { showModal } = useContext(ModalProviderContext);
  const [showUnsubscribeSection, setShowUnsubscribeSection] = useState<boolean>(false);
  const [specialRightsFiles, setSpecialRightsFiles] = useState<File[] | null>(null);
  const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState<boolean>(false);
  const [showSaveErrorAlert, setShowSaveErrorAlert] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    ...passwordValidation,
    app_id: yup.string(),
    use_password: yup.boolean(),
    request_special_rights: yup.boolean(),
    repeat_password: yup.string().when(["use_password"], {
      is: (use_password: boolean) => use_password,
      then: (s) => s.oneOf([yup.ref("password")], $t(t_key.validation.passwords_dont_match))
        .required($t(t_key.validation.required_field))
    }),
    parent_first_name: yup.string().when([], {
      is: () => userDataResponse?.is_underage,
      then: (s) => s.required($t(t_key.validation.required_field))
    }),
    parent_last_name: yup.string().when([], {
      is: () => userDataResponse?.is_underage,
      then: (s) => s.required($t(t_key.validation.required_field))
    }),
    parent_email: yup.string().email().when([], {
      is: () => userDataResponse?.is_underage,
      then: (s) => s.required($t(t_key.validation.required_field))
    }),
    parent_person_code: yup.string(),
    special_rights_files: yup.mixed().when("request_special_rights", {
      is: (value: boolean) => value && userDataResponse?.is_underage === false,
      then: (s) => s.test("hasFiles", $t(t_key.validation.special_rights_file_missing), function() {
        if (specialRightsFiles && specialRightsFiles?.length > 0) {
          return true;
        }
        return false;
      })
    }),
    field_values: yup.object().shape({
      ...fieldValuesValidationSchema(props.session),
      person_code: yup.string().test("validate", $t(t_key.validation.invalid_person_code),
        function(value) {
          if (value) {
            return validatePersonCode(value)
          }
          return true;
        }
      ),
    })
  })

  const methods = useForm({
    mode:"all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      app_id: "",
      use_password: false,
      request_special_rights: false,
      password: "",
      repeat_password: "",
      parent_last_name: "",
      parent_first_name: "",
      parent_person_code: "",
      parent_email: "",
      special_rights_files: [],
      ...fieldValuesFields()
    },
  });

  const reloadPage = () => {
    setSpecialRightsFiles(null);
    loadPage(true);
  }

  const loadPage = async (isRefetch: boolean = false) => {
		if (props.session.application == null) {
			navigate("/");
      return;
		}

    if (!isRefetch) {
      clearAlerts();
      methods.setValue("request_special_rights", false)
      methods.setValue("use_password", false)
    }

		let urlParams = new URLSearchParams(location.search);
		let at = null;
		if (urlParams.has("access_token")) {
			at = urlParams.get("access_token");
		}
    let userData: UserData = await RegisterService.fetchUserData(props.session.application.appId, window.location.toString(), at);
    if (!userData) {
      navigate(`/?app_id=${props.session.application.appId}`);
		}

    setUserDataResponse(userData);
		if (!props.session.session?.local_gov && userData.field_values["local_gov"]) {
			let gov = userData.field_values["local_gov"];
			gov = gov.replaceAll('(', '');
			gov = gov.replaceAll(')', '');
			if (gov.indexOf('|d') !== -1) {
				if (props.session.session) {
					const govParts = gov.split('|d');
					props.session.session.local_gov = govParts[0];
					props.session.session.local_gov_date = govParts[1];
				}
			}
		} else if (props.session.session?.local_gov) {
			props.session.session.local_gov = props.session.session.local_gov.replaceAll('(', '');
			props.session.session.local_gov = props.session.session.local_gov.replaceAll(')', '');
		}
		if (props.session.session?.local_gov_date) {
			props.session.session.local_gov_date = props.session.session.local_gov_date.replaceAll('(', '');
			props.session.session.local_gov_date = props.session.session.local_gov_date.replaceAll(')', '');
		}

		setFormData(await createProfileData(props.session.application ?? null,
      props.session.session?.person_code ?? "",
      null,
      userData,
      false)
    );
	}

  const mapDataToFormFields = () => {
    if (!formData) return;

    methods.setValue("app_id", props.session.application?.appId);

    if (userDataResponse?.is_underage) {
      methods.setValue("parent_email", formData.parent_email)
      methods.setValue("parent_first_name", formData.parent_first_name)
      methods.setValue("parent_last_name", formData.parent_last_name)
      methods.setValue("parent_person_code", formData.parent_person_code)
    }

    props.session.application?.serviceRegFields.map(x => {
      var value = formData.field_values[x.name];
      switch (x.name) {
        case ServiceRegFieldName.Birthday: {
          methods.setValue("field_values.birthday", value)
          break;
        }
        case ServiceRegFieldName.Address: {
          methods.setValue("field_values.address", value)
          break;
        }
        case ServiceRegFieldName.Email: {
          methods.setValue("field_values.email", value)
          break;
        }
        case ServiceRegFieldName.MobilePhone: {
          methods.setValue("field_values.mobile_phone", value)
          break;
        }
        case ServiceRegFieldName.FirstName: {
          methods.setValue("field_values.first_name", value)
          break;
        }
        case ServiceRegFieldName.LastName: {
          methods.setValue("field_values.last_name", value)
          break;
        }
        case ServiceRegFieldName.PersonCode: {
          methods.setValue("field_values.person_code", value)
          break;
        }
        case ServiceRegFieldName.LocalGov: {
          methods.setValue("field_values.local_gov", value)
          break;
        }
        case ServiceRegFieldName.Profession: {
          var values = ""
          if (value) {
            values = value.map((x: string) => {
              return {
                label: $t(`field.profession.${x}`),
                value: x
              }
            })
          }
          methods.setValue("field_values.profession", values)
          break;
        }
        case ServiceRegFieldName.Area: {
          var values = ""
          if (value) {
            values = value.map((x: string) => {
              return {
                label: $t(`field.area.${x}`),
                value: x
              }
            })
          }
          methods.setValue("field_values.area", values);
          break;
        }
        default: {
          break;
        }
      }

    })
  }

  const clearAlerts = (): void => {
    setShowSaveErrorAlert(false);
    setShowSaveSuccessAlert(false);
  }

	const backClick = async () => {
		let urlParams = new URLSearchParams(location.search);
		if (urlParams.has("post_logout_redirect_uri")) {
			const url = urlParams.get("post_logout_redirect_uri");
      if (url) {
        window.location.href = url;//used in mobile app
      }
		} else {
			window.history.back();//used in browsers
		}
	}

  const terminateService = () => {
    setConfirmModal({ 
      open: true,
      title: $t(t_key.profile_view.terminate_service),
      message: $t(t_key.profile_view.are_you_sure_to_terminate_service) 
    });
  }

  const terminateServiceConfirmed = () => {
    if (!formData || props.session.application == null) {
      return;
    }

    setConfirmModal({ open: false, title: "", message: "" });
    RegisterService.startUnRegister({ app_id: formData.app_id }, props.session.application, i18n.language)
			.then((resp: any) => {
				if (!showErrorModal(resp, setErrorModal)) {
					if (props.session.application?.cancelSignature) {
						showModal(<SigningMethodModal
							cacheKey={resp.fileCacheKey}
							data={{ reqKey: resp.requestCacheKey, application: props.session.application }}
							onFinish={finishUnRegistration}
							unRegistration={true}
							identificationCode={formData.field_values["person_code"]}
							phoneNr={formData.field_values["mobile_phone"]}
							onClose={redirectAfterUnRegister} />
						);
					}
					else {
					  finishUnRegistrationNoSign({ reqKey: resp.requestCacheKey, application: props.session.application, fileKey: resp.fileCacheKey })
					}
				}
			})
			.catch(ex => showError(ex));
	}

	const redirectAfterUnRegister = async (resp?: LogoutResponse) => {
		let referrer = sessionStorage.getItem(Constants.URL_REFERRER);
		if (referrer) {
			const lang = sessionStorage.getItem(Constants.LANGUAGE);
			if (lang) {
        if (referrer.endsWith('/')) {
          referrer += lang;
        }
        else {
          referrer += "/"+lang;
        }
      }
    }

		const successPage = `/services/${props.session.application?.appId}/terminated`;
		if (referrer && referrer.length > 10) {
			window.location.href = referrer;
    } else {			
      const post_logout_redirect_uri = props.session.application?.returnUrl ?? successPage;
      if (post_logout_redirect_uri) {
        window.location.href = post_logout_redirect_uri;
      }
    }		
		
		navigate(successPage);
	}

	const finishUnRegistration = async (data: any, containerId: string) => {
		RegisterService.finishUnRegister(data.reqKey, i18n.language, containerId)
			.then((resp: any) => {
				if (!showErrorModal(resp, setErrorModal)) {
					sessionStorage.setItem(Constants.LOGOUT_URLS, JSON.stringify(resp));
				}
			})
			.catch(ex => showError(ex));
	}
	const finishUnRegistrationNoSign = (data: any) => {
		RegisterService.finishUnRegisterNoSign(data.reqKey, i18n.language, data.fileKey)
			.then((resp: any) => {
				if (!showErrorModal(resp, setErrorModal)) {
					sessionStorage.setItem(Constants.LOGOUT_URLS, resp);
				}
			})
			.catch(ex => showError(ex));
	}

	useEffect(() => {
		loadPage();
	}, []);

  useEffect(() => {
    const setFiles = async () => {
      if (specialRightsFiles) {
        let files = [];
        for (var i = 0; i < (specialRightsFiles?.length ?? 0); i++) {
          files.push({
            name: specialRightsFiles![i].name,
            data: await fileToBase64(specialRightsFiles![i]),
            size: specialRightsFiles![i].size
          });
        }
        methods.setValue("special_rights_files", files)
      }
    }
    setFiles();

  }, [specialRightsFiles])

  useEffect(() => {
    if (formData) {
      mapDataToFormFields();
    }
  }, [formData])

	if (formData == null || props.session.application == null) return null;

  const renderGuardianSection = (): JSX.Element => {
    return (
      <Row>
        <h6 tabIndex={0} className="mt-3">{$t(t_key.profile_view.parent_data)}</h6>
        <Col xs={12} md={8} lg={6}>
          <Form.Group className="mb-3" controlId="parentFirstNameControl">
            <Form.Label>
              {$t("field.first_name")} <span className="req-indic">*</span>
            </Form.Label>
            <Controller
              control={methods.control}
              name={"parent_first_name"}
              render={({field}) => (
                <Form.Control
                  ref={field.ref}
                  value={field.value}
                  isValid={!methods.formState.errors.parent_first_name && !!field.value}
                  isInvalid={!!methods.formState.errors.parent_first_name}
                  aria-invalid={!!methods.formState.errors.parent_first_name}
                  aria-describedby={
                    !!methods.formState.errors.parent_first_name
                      ? methods.formState.errors.parent_first_name.message 
                      : ""
                  }
                  onChange={field.onChange}
                />
              )}
            />
            { methods.formState.errors.parent_first_name && 
              <Form.Text className="text-danger" role="alert">
                { methods.formState.errors.parent_first_name?.message as string }
              </Form.Text>
            }
          </Form.Group>
        </Col>

        <Col xs={12} md={8} lg={6}>
          <Form.Group className="mb-3" controlId="parentLastNameControl">
            <Form.Label>
              {$t("field.last_name")} <span className="req-indic">*</span>
            </Form.Label>
            <Controller
              control={methods.control}
              name={"parent_last_name"}
              render={({field}) => (
                <Form.Control
                  aria-required={true}
                  value={field.value}
                  isValid={!methods.formState.errors.parent_last_name && !!field.value}
                  isInvalid={!!methods.formState.errors.parent_last_name}
                  aria-invalid={!!methods.formState.errors.parent_last_name}
                  aria-describedby={
                    !!methods.formState.errors.parent_last_name
                      ? methods.formState.errors.parent_last_name.message 
                      : ""
                  }
                  onChange={field.onChange}
                />
              )}
            />
            { methods.formState.errors.parent_last_name && 
              <Form.Text className="text-danger" role="alert">
                { methods.formState.errors.parent_last_name?.message as string }
              </Form.Text>
            }
          </Form.Group>
        </Col>

        <Col xs={12} md={8} lg={6}>
          <Form.Group className="mb-3" controlId="parentEmailControl">
            <Form.Label>
              {$t("field.email")} <span className="req-indic">*</span>
            </Form.Label>
            <Controller
              control={methods.control}
              name={"parent_email"}
              render={({field}) => (
                <Form.Control
                  value={field.value}
                  isValid={!methods.formState.errors.parent_email && !!field.value}
                  isInvalid={!!methods.formState.errors.parent_email}
                  onChange={field.onChange}
                  aria-invalid={!!methods.formState.errors.parent_email}
                  aria-required={true}
                  aria-describedby={
                    !!methods.formState.errors.parent_email
                      ? methods.formState.errors.parent_email.message 
                      : ""
                  }
                />
              )}
            />

            { methods.formState.errors.parent_email && 
              <Form.Text className="text-danger" role="alert">
                { methods.formState.errors.parent_email?.message as string }
              </Form.Text>
            }
          </Form.Group>
        </Col>
      </Row>
    )
  }

  const renderActiveSpecialRightsLabel = (): JSX.Element => {
    let label = $t(t_key.profile_view.special_request_status_accepted,
      { endDate: $t(t_key.profile_view.special_request_status_date_indefinite)});

    if (userDataResponse?.special_rights_end_date) {
      label = $t(t_key.profile_view.special_request_status_accepted,
        { endDate: userDataResponse.special_rights_end_date });
    }

    return (
      <Label type={LabelType.Success}>
        <strong>{$t(t_key.profile_view.special_request_status_prefix)}: </strong> 
        {label}
      </Label>
    )
  }

  const renderPendingSpecialRightsLabel = (): JSX.Element => {
    return (
      <Label type={LabelType.Error}>
        <strong>{$t(t_key.profile_view.special_request_status_prefix)}: </strong> 
        {$t(t_key.profile_view.special_request_status_pending)}
      </Label>
    )
  }

  const onSubmit = (data: any) => {
    if (props.session.application === null) return;

    RegisterService.save(data, props.session.application, i18n.language)
			.then((resp) => {
			  if (resp.errors && resp.errors.global) {
					setErrorModal({ open: true, message: $t(resp.errors.global[0]) });
				} else if (resp.success) {
          reloadPage();

          setShowSaveSuccessAlert(true);
          setShowSaveErrorAlert(false);
        } else if (resp.errors) {
          setShowSaveSuccessAlert(false);
          setShowSaveErrorAlert(true);
        }
			})
			.catch(ex => {
        showError(ex)
        setShowSaveSuccessAlert(false);
        setShowSaveErrorAlert(true);
      });
  }

  const onSubmitError = (data: any) => {
    console.error(data);
  }

  const renderForm = (): JSX.Element => {
    const hiddenFields = [
      ServiceRegFieldName.LocalGov,
      ServiceRegFieldName.FirstName,
      ServiceRegFieldName.LastName,
      ServiceRegFieldName.PersonCode
    ]

    const readOnlyFields = [
      ServiceRegFieldName.Birthday,
      ServiceRegFieldName.LocalGov
    ];

    return (
      <FormProvider {...methods} >
        <Form onSubmit={methods.handleSubmit(onSubmit, onSubmitError)} className="data-form" noValidate>
          <h1 tabIndex={0} id={Constants.MAIN_CONTENT}>{$t(t_key.profile_view.title)}</h1>
          <Row tabIndex={-1}>
            <Col xs={12} md={8} lg={9}>
              <ProfileComponent isRegister={false} isParent={false} formData={formData} />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div className="label-wrapper mt-3">
                { userDataResponse?.has_special_rights &&
                  renderActiveSpecialRightsLabel()
                }

                { userDataResponse?.has_pending_special_application &&
                  renderPendingSpecialRightsLabel()
                }
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            {props.session.application?.serviceRegFields?.map(function(f) {
              if (hiddenFields.find(x => x === f.name)) return;

              if (readOnlyFields.find(x => x === f.name)) {
                return (
                 <FormField 
                    fld={f}
                    disabled={true}
                    readOnly={true}
                  />
                )
              }
              return (
                <FormField fld={f} />
              );
            })}
          </Row>

          { userDataResponse?.is_underage &&
            renderGuardianSection()
          }
          <Row className="mt-4">
            <Col xs={12}>
              <Controller
                control={methods.control}
                name={"use_password"}
                render={({field}) => (
                  <>
                    <Form.Check
                      id="login-with-password-check"
                      checked={field.value}
                      type="switch"
                      inline
                      label={$t(t_key.profile_view.change_or_login_with_password)}
                      onChange={field.onChange}
                      aria-pressed={field.value}
                    />

                    {field.value &&
                      <Row className="mt-2">
                        <ChangePasswordFormPart />
                      </Row>
                    }
                  </>
                )}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <Controller
                control={methods.control}
                name={"request_special_rights"}
                render={({field}) => (
                  <>
                    <Form.Check
                      id="request-special-rights-check"
                      checked={field.value}
                      type="switch"
                      inline
                      label={$t(t_key.profile_view.special_request_service_title)}
                      onChange={field.onChange}
                      aria-pressed={field.value}
                    />

                    { field.value &&
                      <SpecialRightsFormPart setFiles={setSpecialRightsFiles}
                        isUnderage={userDataResponse?.is_underage ?? true}
                      />
                    }
                  </>
                )}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <Form.Check
                id="terminate-service-check"
                checked={showUnsubscribeSection}
                type="switch"
                inline
                label={$t(t_key.profile_view.terminate_service)}
                onChange={e => setShowUnsubscribeSection(e.target.checked)}
                aria-pressed={showUnsubscribeSection}
              />
              { showUnsubscribeSection &&
                <Col xs={12} className={classNames("mt-4", {"form-buttons": isMobile})}>
                  <Button variant="secondary" onClick={terminateService}>
                    {$t(t_key.buttons.terminate_service)}
                  </Button> 
                </Col>
              }
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12} className="form-buttons">
              <Button variant="secondary" onClick={backClick}>
                {$t(t_key.buttons.back_to_service)}
              </Button>
              <Button type="submit">{$t(t_key.buttons.save)}</Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    )
  }

  return (
    <Container>
      {showSaveSuccessAlert &&
        <Alert variant="success" tabIndex={-1} className="page-alert mt-3">
          <div className="container">
            {$t(t_key.general.save_success)}
          </div>
        </Alert>
      }

      {showSaveErrorAlert &&
        <Alert variant="danger" tabIndex={-1} className="page-alert mt-3">
          <div className="container">
            {$t(t_key.general.save_error)}
          </div>
        </Alert>
      }

      {renderForm()}

      {confirmModal.open ? 
        <ConfirmModal
          show={confirmModal.open}
          title={confirmModal.title}
          message={confirmModal.message}
          onCancel={() => setConfirmModal({open: false, title: "", message: ""})}
          onOk={() => terminateServiceConfirmed()}
        /> 
        : null
      }
      {errorModal.open ?
        <ErrorModal show={errorModal.open}
          message={errorModal.message}
          onClose={() => setErrorModal({ open: false, message: "" })}
        /> : null
      }
    </Container>
  )
}

export default ProfilePage;
