import Constants from "Constants";
import { $t, t_key } from "plugins/i18n";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

type ParentFormPartProps = {
    requiresParentApproval: boolean,
    registerUnderAge: boolean,
}

const ParentFormPart = (props: ParentFormPartProps) => {
  const {control, formState: {errors}} = useFormContext();

  if (!props.registerUnderAge && !props.requiresParentApproval) {
    return <></>;
  }

  return (
    <>
      <Row className="mt-3" id={Constants.MAIN_CONTENT}>
        <h2 className="mb-3">{$t(t_key.register_view.parent_data)}</h2>
        { !props.registerUnderAge && (
          <>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-3" controlId="parentFirstNameControl">
                <Form.Label>
                  {$t("field.first_name")} <span className="req-indic">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name={"parent_first_name"}
                  render={({field}) => (
                    <Form.Control
                      ref={field.ref}
                      value={field.value}
                      isValid={!errors.parent_first_name && !!field.value}
                      isInvalid={!!errors.parent_first_name}
                      onChange={field.onChange}
                      aria-required={true}
                      required
                    />
                  )}
                />

                { errors.parent_first_name && 
                  <Form.Text className="text-danger" role="alert">
                    { errors.parent_first_name?.message as string }
                  </Form.Text>
                }
              </Form.Group>
            </Col>

            <Col xs={12} sm={6}>
              <Form.Group className="mb-3" controlId="parentLastNameControl">
                <Form.Label>
                  {$t("field.last_name")} <span className="req-indic" aria-hidden>*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name={"parent_last_name"}
                  render={({field}) => (
                    <Form.Control
                      value={field.value}
                      isValid={!errors.parent_last_name && !!field.value}
                      isInvalid={!!errors.parent_last_name}
                      onChange={field.onChange}
                      aria-required={true}
                      required
                    />
                  )}
                />
                { errors.parent_last_name && 
                  <Form.Text className="text-danger" role="alert">
                    { errors.parent_last_name?.message as string }
                  </Form.Text>
                }
              </Form.Group>
            </Col>

            {!props.requiresParentApproval &&
              <Col xs={12} sm={6}>
                <Form.Group className="mb-3" controlId="parentPersonCodeControl">
                  <Form.Label>
                    {$t("field.person_code")} <span className="req-indic" aria-hidden>*</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name={"parent_person_code"}
                    render={({field}) => (
                      <Form.Control
                        value={field.value}
                        isValid={!errors.parent_person_code && !!field.value}
                        isInvalid={!!errors.parent_person_code}
                        onChange={field.onChange}
                        aria-required={true}
                        required
                      />
                    )}
                  />
                  { errors.parent_person_code && 
                    <Form.Text className="text-danger" role="alert">
                      { errors.parent_person_code?.message as string }
                    </Form.Text>
                  }
                </Form.Group>
              </Col>
            }
          </>
        )}

        <Col xs={12} sm={6}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="parent-email">
              {$t("field.email")} <span className="req-indic" aria-hidden>*</span>
            </Form.Label>
            <Controller
              control={control}
              name={"parent_email"}
              render={({field}) => (
                <Form.Control
                  id="parent-email"
                  ref={field.ref}
                  value={field.value}
                  isValid={!errors.parent_email && !!field.value}
                  isInvalid={!!errors.parent_email}
                  onChange={field.onChange}
                  aria-required={true}
                  required
                />
              )}
            />

            { errors.parent_email && 
              <Form.Text className="text-danger" role="alert">
                { errors.parent_email?.message as string }
              </Form.Text>
            }
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default ParentFormPart;

