import { Col, Form } from "react-bootstrap";
import { ServiceRegField, ServiceRegFieldName } from "../../models/application_info";
import { $t, t_key } from "../../plugins/i18n";
import AdsAutocomplete from "./AdsAutocomplete";
import DatePicker from 'react-datepicker';
import Select from "react-select";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

type SelectOption = {
  value: string;
  label: string;
};

export type FormFieldProps = {
  fld: ServiceRegField,
  formName?: string,
  value?: any,
  errorMessage?: any,
  disabled: boolean,
  readOnly: boolean,
  change?(e: any): void,
  isMobile: boolean,
} & typeof defaultProps;

const defaultProps = {
  readOnly: false,
  disabled: false,
  isMobile: false,
}

const AriaDesc = {
  ErrorPrefix: "error_",
  MobilePhone: "mobile-phone-desc"
}

export const FormField = (props: FormFieldProps) => {
  const { control, formState: {errors} } = useFormContext();
  const [options, setOptions] = React.useState<SelectOption[] | null>(null);
  const [allOptions, setAllOptions] = React.useState<SelectOption[] | null>(null);

  /* note(kristen):
   * Aria description needs to refer to an element which
   * has corresponding id and has the text inside of the element.
   *
   * Correct example:
   *```
   * <div id="desc-id">This is a description</div>
   * <div aria-describedby="desc-id">
   *    this element is described by element 'desc-id'
   * </div>
   * ```
   */
  const ariaDescription = (fieldName: string): string => {
    const errorsExist: boolean = errors.field_values !== undefined

    if (!errorsExist) {
      if (fieldName === ServiceRegFieldName.MobilePhone) {
        return AriaDesc.MobilePhone
      }
    }

    // if input is invalid add field description and field error
    // messages together
    if (errorsExist) {
      var description = `${AriaDesc.ErrorPrefix}${fieldName}`
      if (fieldName === ServiceRegFieldName.MobilePhone) {
        return `${description} ${AriaDesc.MobilePhone}`
      }

      return description
    }

    return "";
  }

  const renderFieldControl = (
    serviceField: ServiceRegField,
  ): JSX.Element => {
    if (serviceField.type === "date") {
      return (
        <>
          <Controller
            control={control}
            name={`field_values.${serviceField.name}` as any}
            render={({field}) => (
              <DatePicker
                ref={field.ref}
                dateFormat="dd.MM.yyyy"
                className={
                  (errors.field_values !== undefined 
                    // @ts-ignore
                    && !!errors.field_values[serviceField.name]?.message
                  )
                    ? "is-invalid" 
                    : ""
                }
                selected={field.value}
                id={serviceField.name}
                onChange={field.onChange}
                disabled={props.disabled}
                readOnly={props.readOnly}
                aria-required={props.fld.required}
              />
            )}
          />
        </>
      )
    }
    else if (serviceField.type === "address") {
      return (
        <Controller
          control={control}
          name={`field_values.${serviceField.name}` as any}
          render={({field}) => (
            <AdsAutocomplete
              ref={field.ref}
              value={field.value}
              inputId={serviceField.name}
              onChange={(x) => field.onChange(x ?? "")}
              disabled={props.disabled}
              isReadOnly={props.readOnly}
              isValid={
                !!field.value
              }
              isInvalid={
                // @ts-ignore
                errors.field_values !== undefined && errors.field_values[serviceField.name]
              }
              aria-required={props.fld.required}
            />
          )}
        />
      )
    } else if (serviceField.type === "multi_choice") {
      return (
        <Controller
          control={control}
          name={`field_values.${serviceField.name}` as any}
          render={({field}) => (
            <Select
              ref={field.ref}
              className={`styled-container ${!!props.errorMessage ? 'is-invalid' : ''}`}
              value={field.value}
              inputId={props.fld.name}
              options={allOptions ?? []}
              isMulti
              classNamePrefix="select"
              placeholder={$t(t_key.register_view.select_empty)}
              aria-describedby={
                errors.field_values === undefined 
                  ? "" 
                  // @ts-ignore
                  : errors.field_values[serviceField.name]?.message
              }
              aria-invalid={
                errors.field_values !== undefined 
                  // @ts-ignore
                  && !!errors.field_values[serviceField.name]
              }
              aria-required={props.fld.required}
              onChange={field.onChange}
            />
          )}
        />
      )
    }
    return (
      <Controller 
        control={control}
        name={`field_values.${serviceField.name}` as any}
        render={({field}) => (
          <Form.Control
            id={serviceField.name}
            ref={field.ref}
            readOnly={props.readOnly}
            disabled={props.disabled}
            isValid={
              !!field.value
            }
            isInvalid={
              // @ts-ignore
              errors.field_values !== undefined && errors.field_values[serviceField.name]
            }
            value={field.value}
            onChange={field.onChange}
            aria-describedby={ariaDescription(serviceField.name)}
            aria-invalid={
              errors.field_values !== undefined 
                // @ts-ignore
                && !!errors.field_values[serviceField.name]
            }
            aria-required={props.fld.required}
            required={props.fld.required}
          />
        )}
      />
    )
  }

  const renderFieldDescription = (fieldName: string): JSX.Element => {
    return (
      <>
        {fieldName === ServiceRegFieldName.MobilePhone &&
          <i id={AriaDesc.MobilePhone} style={{display: "block"}} tabIndex={-1}>
            {$t(t_key.field.mobile_phone_desc)}
          </i>
        }
      </>
    );

  }

  useEffect(() => {
    if (props.fld.type !== "multi_choice" || !props.fld.choices) {
      return;
    }

    let tmpSel = [] as SelectOption[];
    let tmpAll = props.fld.choices.map(x => ({
      value: x,
      label: $t(`field.${props.fld.name}.${x}`)
    } as SelectOption));

    if (props.value) {
      for (let item of props.value) {
        let opt = tmpAll.find(x => x.value == item);
        if (opt)
        tmpSel.push(opt);
      }
    }

    setAllOptions(tmpAll);
    setOptions(tmpSel);
  }, []);

  const renderMobileFieldLabel = (field: ServiceRegField) => {
    const requiredFieldLabel = `${$t(`field.${field.name}`)} required`

    return (
      <>
        {field.required 
          ?
          <span style={{display: "none"}} id={`required-${field.name}`}>
            {requiredFieldLabel}
          </span>
          : 
          <span style={{display: "none"}} id={`opt-${field.name}`}>
            {$t(`field.${field.name}`)}
          </span>
        }
      </>
    )
  }

  return (
    <Col key={props.fld.name} xs={12} sm={6}>
      {props.isMobile &&
        renderMobileFieldLabel(props.fld)
      }

      <Form.Group className="mb-3" >
        {props.isMobile && props.fld.required &&
          <Form.Label
            htmlFor={props.fld.name}
            tabIndex={-1}
            aria-labelledby={`required-${props.fld.name}`}
          >
            {$t(`field.${props.fld.name}`)} <span className="req-indic" aria-hidden>*</span> 
          </Form.Label>
        }

        {props.isMobile && !props.fld.required &&
          <Form.Label htmlFor={props.fld.name} tabIndex={-1}>
            {$t(`field.${props.fld.name}`)} 
          </Form.Label>
        }

        {!props.isMobile &&
          <Form.Label htmlFor={props.fld.name} tabIndex={-1}>
            {$t(`field.${props.fld.name}`)} 
            { props.fld.required 
              ? <span className="req-indic" aria-hidden={!props.isMobile}>*</span> 
              : null
            }
          </Form.Label>
        }

        {renderFieldControl(props.fld)}
        {renderFieldDescription(props.fld.name)}

        { // @ts-ignore
          errors.field_values && 
            <Form.Text role="alert" className="text-danger" id={`${AriaDesc.ErrorPrefix}${props.fld.name}`}>
              { // @ts-ignore
                errors.field_values[props.fld.name]?.message as string
              }
            </Form.Text>
        }
      </Form.Group>
    </Col>
  )
}

FormField.defaultProps = defaultProps;
