import { ApplicationInfo } from "../models/application_info";

export enum UserStatus {
	Unauthorized = 1,
	NoAccount = 2,
	NoAppRegistered = 4,
	Active = 3,
}

export const SessionAction = {
	sign_terms: "sign_terms"
}

export type UserInfo = {
	user_status: UserStatus,
	person_code: string,
	local_gov: string;
	local_gov_date: string;
	can_register: boolean | null,
	requires_parent_approval: boolean | null,
	user_sierra_id?: number,
	password_enabled: boolean | null
}

export type SessionInfo = {
	session: UserInfo | null;
	application: ApplicationInfo | null;
	languages: string[];
	redirectUrl?: string | null;	
	do_sso_redirect: boolean;
	requires_captcha: boolean;
	recaptcha_site_key: string;
	soft_login?: boolean;
	auto_logout: boolean;
	tara_notifications?: string[];
	error?:any;
}
