import classNames from "classnames";

export enum LabelType {
  Success,
  Error
}

type LabelProps = {
  type: LabelType,
  class?: string,
  children: React.ReactNode 
} & typeof defaultProps;

const defaultProps = {
  class: ""
}

export const Label = (props: LabelProps): JSX.Element => {
  return (
    <div className={
      classNames(`label-container ${props.class}`,
        {"label-container--success": props.type === LabelType.Success},
        {"label-container--error": props.type === LabelType.Error}
      )
    }>
      {props.children}
    </div>
  );
}

Label.defaultProps = defaultProps;
