import React from "react";
import { Col, Row, Button, Form, Modal } from "react-bootstrap";
import { $t, t_key } from "../../plugins/i18n";
import { useTranslation } from 'react-i18next';
import { hideValidationErrors, highlightFirstLocalError, showValidationErrors, validateEmail } from "../../plugins/validation";
import { PasswordService } from "../../services/password-service";
import { SessionInfo } from "../../models/session_info";

type ForgotPasswordModalProps = {
	show: boolean,
	session: SessionInfo,
	onClose: () => void
}

type ForgotPasswordData = {
	email: string
}

const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
	const { i18n } = useTranslation();

	const [formData, setFormData] = React.useState<ForgotPasswordData>({ email: "" });
	const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});
	const [step, setStep] = React.useState<number>(1);
	const [resultMessage, setResultMessage] = React.useState<string>("");
	const [resultDetails, setResultDetails] = React.useState<string>("");

	const setField = (val: string, fld: string) => {
		let tmp = { ...formData } as { [key: string]: any };
		tmp[fld] = val;
		setFormData(tmp as ForgotPasswordData);
	}
	const validate = () => {
		let locErrors = { ...localErrors, email: null } as { [key: string]: any };
		let isValid = true;

		if (!formData.email) {
			isValid = false;
			locErrors.email = $t(t_key.validation.required_field);
		}
		else if (!validateEmail(formData.email)) {
			isValid = false;
			locErrors.email = $t(t_key.validation.invalid_email);
		}
		setLocalErrors(locErrors);
		if (!isValid) {
			highlightFirstLocalError();
		}
		return isValid;
	}
	const forgotPassClick = () => {
		if (!validate() || props.session.application == null)
			return;

		hideValidationErrors(localErrors, setLocalErrors);

		PasswordService.sendReminder(i18n.language, props.session.application.appId, formData.email)
			.then((resp) => {
				if (!showValidationErrors(resp, setLocalErrors)) {
					setResultMessage($t(t_key.forgot_password_view.success_title));
					setResultDetails($t(t_key.forgot_password_view.success_description));
					setStep(2);
				}
			})
			.catch(() => {
				setResultMessage($t(t_key.forgot_password_view.failed_title));
				setResultDetails($t(t_key.forgot_password_view.failed_description));
				setStep(2);
			});
		
	}
	const forgotPassClose = () => {
		props.onClose();
	}

	return <Modal show={props.show} onHide={forgotPassClose} className="ra-modal" size="lg">
		<Modal.Header closeButton>
			<Modal.Title>{step === 1 ? $t(t_key.forgot_password_view.title) : resultMessage}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			{step === 1 ?
				<React.Fragment>
					<Row className="mb-3">
						<Col xs={12}>
							<div>{$t(t_key.forgot_password_view.message)}</div>
						</Col>
					</Row>
					<Row className="justify-content-md-center mb-2">
						<Col xs={8}>
							<Form.Group controlId="name">
								<Form.Label>{$t(t_key.forgot_password_view.e_mail_address)} <span className="req-indic">*</span></Form.Label>
								<Form.Control
									value={formData.email}
									aria-describedby={!localErrors.email ? "" : $t(localErrors.email)}
									aria-invalid={!!localErrors.email}
									onChange={e => setField(e.target.value, "email")}
									isInvalid={!!localErrors.email}
								/>
								{!!localErrors.email ? <Form.Control.Feedback type="invalid">{localErrors.email}</Form.Control.Feedback> : null}
							</Form.Group>
						</Col>
					</Row>
				</React.Fragment>
				:
				<Row className="mb-3">
					<Col xs={12}>
						<div>{resultDetails}</div>
					</Col>
				</Row>
			}
		</Modal.Body>
		<Modal.Footer>
			{step === 1 ?
				<Button variant="primary" onClick={() => forgotPassClick()}>
					{$t(t_key.buttons.send)}
				</Button> :
				<Button variant="primary" onClick={() => forgotPassClose()}>
					{$t(t_key.buttons.close)}
				</Button>
			}
		</Modal.Footer>
	</Modal>;
}

export default ForgotPasswordModal;
