import { Col, Row, Button, Modal } from "react-bootstrap";
import { $t, t_key } from "../plugins/i18n";

type ConfirmModalProps = {
	show: boolean,
	title: string,
	message: string
	onCancel: () => void,
	onOk: () => void
}

const ConfirmModal = (props: ConfirmModalProps) => {
	return <Modal show={props.show} onHide={props.onCancel} className="ra-modal" size="lg">
		<Modal.Header closeButton>
			<Modal.Title>{props.title}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row className="mb-3">
				<Col xs={12}>
					<div>{props.message}</div>
				</Col>
			</Row>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={props.onCancel}>
				{$t(t_key.buttons.cancel)}
			</Button>
			<Button variant="primary" onClick={props.onOk}>
				{$t(t_key.buttons.confirm)}
			</Button>
		</Modal.Footer>
	</Modal>;
}

export default ConfirmModal;
