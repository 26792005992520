import { $t, t_key } from "plugins/i18n";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalProviderContext } from "./modal-provider";

type ErrorProps = {
    nextModal?: React.ReactNode;
    title: string;
    content: React.ReactNode;
}

const ErrorModal = (props: ErrorProps) => {
    const { hideModal, showModal } = useContext(ModalProviderContext);

    const onClose = () => {
        if (!!props.nextModal) {
            showModal(props.nextModal);
        }
        else {
            hideModal();
        }
    }

    return (
        <Modal show onHide={onClose} className="ra-modal error-modal" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {!props.title ? $t(t_key.validation.error_occured) : props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.content}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClose}>
                    {$t(t_key.buttons.close)}
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ErrorModal;