import { Session } from "inspector";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RegisterModal from "../components/register/register-modal";
import Constants from "../Constants";
import { SessionInfo, UserStatus } from "../models/session_info";
import { $t, t_key } from "../plugins/i18n";
import { LogoutService } from "../services/logout-service";


export type MainPageProps = {
  session: SessionInfo
}

const LogoutPage = (props: MainPageProps) => {
  const { i18n } = useTranslation();

  const logout = async () => {
    const autoLogout = props.session.auto_logout;
    if (autoLogout == true) {
      await callLogout(false);
    }  
  }

  const callLogout = async(manualLogout: boolean) => {
    let urlParams = new URLSearchParams(location.search);
    let post_logout_redirect_uri = urlParams.get("post_logout_redirect_uri");
    let id_token_hint = urlParams.get("id_token_hint");
    let state = urlParams.get("state");

    if (state) {
      if (post_logout_redirect_uri?.indexOf('?') !== -1)
        post_logout_redirect_uri += '&state=' + state;
      else
        post_logout_redirect_uri += '?state=' + state;
    }    

    const path = window.location.pathname;
    if (path.startsWith("/saml")) {
      const response = await LogoutService.logoutSAML(location.search, manualLogout);
      if (response?.otherAppsLogoutUrls?.length) {
        await logoutFromOtherApps(response.otherAppsLogoutUrls, response.redirectBackUrl!);
      } else if (response.redirectBackUrl) {
        window.location.href = response.redirectBackUrl;
      }
    }
    if (path.startsWith("/cas")) {
      const response = await LogoutService.logoutCAS(location.search, manualLogout);
      if (response?.otherAppsLogoutUrls?.length) {
        await logoutFromOtherApps(response.otherAppsLogoutUrls, post_logout_redirect_uri);
      }
      else if (post_logout_redirect_uri)
        window.location.href = post_logout_redirect_uri;
    }
    if (path.startsWith("/oidc")) {
      let response: any;
      if (id_token_hint) {
        response = await LogoutService.endSessionOIDC(location.search, manualLogout);
      }

      if (response?.otherAppsLogoutUrls?.length) {
        await logoutFromOtherApps(response.otherAppsLogoutUrls, post_logout_redirect_uri);
      }
      else if (post_logout_redirect_uri)
        window.location.href = post_logout_redirect_uri;
    }
  }
  
  let tab: any;
  const logoutFromOtherApps = async (otherAppsLogoutUrls: string[], post_logout_redirect_uri: string | null): Promise<any> => {
    let i = 1;
    for (const element of otherAppsLogoutUrls) {
      const logoutUrl = element;
      try {
        if (!tab)
          tab = window.open(logoutUrl, '_blank');
        else {
          window.setTimeout(() => {
            tab.location.href = logoutUrl;
          }, i * 2000);
          i++;
        }        
      } catch (e) {
      }
    }
    await new Promise(r => setTimeout(r, i * 2000));
    if (tab)
      tab.close();
    if (post_logout_redirect_uri)
      window.location.href = post_logout_redirect_uri;        
  }


  React.useEffect(() => {
    logout();
  });

  return (
    <Container className="content-area">
      <Row>
        <Col xs={12} className="col1 text-center" >
          <Button variant='primary' onClick={() => { callLogout(true); }}>{$t(t_key.logout_view.only_this_btn_text)}</Button>
          <br />
          <br />
          <Button variant='primary' onClick={() => { callLogout(false); }}>{$t(t_key.logout_view.all_btn_text)}</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default LogoutPage;
