import { MapFromSierraAddress } from "../plugins/address-helper";
import { ApplicationInfo } from "./application_info";
import { UserData } from "./user_data";

export const ProfileReadOnlyFields = [
  "person_code",
  "first_name",
  "last_name",
  "local_gov"
];

export type ProfileData = {
  app_id: string,
  person_code: string,
  full_name: string,
  parent_first_name: string,
  parent_last_name: string,
  parent_person_code: string,
  parent_email: string,
  use_password: boolean,
  password: string,
  repeat_password: string,
  field_values: { [key: string]: any },
  identities: string[] | null,
  account_exists: boolean,
  local_gov: string
  local_gov_date: string
  has_pending_special_application: boolean,
  has_special_rights: boolean,
  special_rights_end_date: string,
  special_rights_files?: SpecialRightsFile[],
  request_special_rights: boolean
}

export type SpecialRightsFile = {
  name: string,
  data: string | ArrayBuffer | null,
  size: number
}

export const createProfileData = async (
  app: ApplicationInfo | null,
  person_code: string,
  local_gov: string | null = null,
  userData: UserData | null, forUnderage: boolean
) => {
  let gov = userData?.field_values["local_gov"];
  let gov_date = null;

  if (local_gov) {
    gov = local_gov
  }

  if (gov) {
    gov = gov.replaceAll('(', '');
    gov = gov.replaceAll(')', '');
  }

  if (gov && gov.indexOf('|d') !== -1) {      
    const govParts = gov.split('|d');
    gov = govParts[0];
    gov_date = govParts[1];
  }

  let ret = {
    app_id: app?.appId ?? "",
    person_code: forUnderage ? "" : person_code,
    parent_first_name: "",
    parent_last_name: "",
    parent_person_code: forUnderage ? person_code : "",
    parent_email: "",
    use_password: userData != null ? userData.use_password : false,
    password: "",
    repeat_password: "",
    field_values: {},
    identities: userData?.identities,
    account_exists: userData?.account_exists ?? false,
    local_gov: gov,
    local_gov_date: gov_date,
    has_pending_special_application: userData?.has_pending_special_application,
    has_special_rights: userData?.has_special_rights
  } as ProfileData;

  if (app != null) {
    let firstName = "";
    let lastName = "";
    let personCode = "";
    let email = "";
    for (let f of app.serviceRegFields) {
      if (f.name === "local_gov" && !forUnderage) {
        ret.field_values[f.name] = gov;
        continue;
      }

      let val = "";
      if (userData) {
        switch (f.name) {
          case "first_name":
            firstName = userData.field_values[f.name];
            break;
          case "last_name":
            lastName = userData.field_values[f.name];
            break;
          case "person_code":
            personCode = userData.field_values[f.name];
            break;
          case "email":
            email = userData.field_values[f.name];
            break;
          default:
            break;
        }

        if (userData.field_values[f.name] && !forUnderage) {
          if (f.type == "date") {
            let d = new Date(userData.field_values[f.name]);
            ret.field_values[f.name] = d.toString() === "Invalid Date" ? "" : d;
            continue;
          }
          else if (f.type == "address") {
            val = await MapFromSierraAddress(userData.field_values[f.name]);
          }
          else if (f.type == "multi_choice") {
            if (userData.field_values[f.name]) {
              val = userData.field_values[f.name].split("$$");
            }
          }
          else
          val = userData.field_values[f.name];
        }
      }
      ret.field_values[f.name] = val;
    }

    if (forUnderage) {
      ret.full_name = "";
      ret.parent_first_name = firstName;
      ret.parent_last_name = lastName;
      ret.parent_person_code = personCode;
      ret.parent_email = email;
    }
    else {
      ret.person_code = personCode;
      ret.full_name = `${firstName} ${lastName}`.trim();
      ret.parent_first_name = userData?.parent_first_name ?? "";
      ret.parent_last_name = userData?.parent_last_name ?? "";
      ret.parent_person_code = userData?.parent_person_code ?? "";
      ret.parent_email = userData?.parent_email ?? "";
    }
  }

  return ret;
}
