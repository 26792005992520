import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { SessionInfo } from "../models/session_info";
import { matchPath } from 'react-router';
import { AuthService } from "./auth-service";
import { getCookie } from "../plugins/cookie-helper";
import { Setting } from "../models/settings";


const defaultLanguages = ['en', 'et', 'ru'];

export const SessionService = {

  getAccessabilitySettings: async function (): Promise<Setting[]> {
    let url = BackendConfig.Url() + "/Session/accessability";
    const result: Setting[] = await BackendApi.get(url)
    return result;
  },
  getSessionWithOidcAccesstoken: async function (access_token:string): Promise<SessionInfo | null> {
    let url = BackendConfig.Url() + "/Session/oidc_at/" + access_token;
    const result: SessionInfo = await BackendApi.get(url)
    return result;
  },
  getSession: async function (): Promise<SessionInfo | null> {
    let urlParams = new URLSearchParams(location.search);
    let app_id = urlParams.get("app_id");

    if (!app_id) {
      const pathMatch = matchPath({ path: "services/:app_id/*" }, window.location.pathname);
      app_id = pathMatch == null ? null : pathMatch.params["app_id"] as string;
    }


    if (!app_id) {
      const pathMatch = matchPath({ path: ":auth_type/login/success" }, window.location.pathname);
      if (pathMatch != null) {
        const cookieValue = getCookie("challenge");
        let resp = null;

        if (pathMatch.params["auth_type"] == "tara")
        {
          if (!!urlParams.get("error")) {
            await AuthService.authorizeFailure(urlParams.get("error"), urlParams.get("error_description"));
            window.history.go(-2);
            return null;
          }
          resp = await AuthService.taraSuccess(urlParams.get("code") ?? "", urlParams.get("state") ?? "", cookieValue ?? null);
        }
        else if (pathMatch.params["auth_type"] == "harid")
          resp = await AuthService.harIdSuccess(urlParams.get("code") ?? "", urlParams.get("state") ?? "", cookieValue ?? null);
        else if (pathMatch.params["auth_type"] == "taat")
          resp = await AuthService.TAATSuccess(urlParams.get("RelayState"));

        if (resp != null) {
          return {
            session: resp.session === undefined ? null : resp.session,
            application: resp.application === undefined ? null : resp.application,
            languages: resp.languages ?? [],
            redirectUrl: resp.redirectUrl === undefined ? null : resp.redirectUrl,
            do_sso_redirect: false,
            requires_captcha: resp.requires_captcha === undefined ? false : resp.requires_captcha,
            recaptcha_site_key: resp.recaptcha_site_key === undefined ? "" : resp.recaptcha_site_key,
            soft_login: resp.soft_login === undefined || resp.soft_login === null || resp.soft_login ? true : false,
            auto_logout: true,
            error: resp.error
          };
        }
      }
    }

    if (app_id != null) {
      let queryParams = new URLSearchParams(location.search);
      queryParams.set("app_id", app_id);

      const url = BackendConfig.Url() + "/Session?" + queryParams.toString();
      const result: SessionInfo = await BackendApi.get(url);
      const samlReturnUrl = queryParams.get("ReturnUrl");
      if (samlReturnUrl)
        result.redirectUrl = samlReturnUrl;
      return result;
    }

    if (window.location.pathname.indexOf("logout") > 0 || window.location.pathname.indexOf("endsession") > 0) {
      const url = BackendConfig.Url() + "/Session/active" + location.search;
      const resp = await BackendApi.get(url);
      return resp;
    }


    return {
      session: null,
      application: null,
      languages: defaultLanguages,
      do_sso_redirect: false,
      requires_captcha: false,
      recaptcha_site_key: "",
      auto_logout: true,
    };
  },

  getAppReturnUrl: async function (appId: string): Promise<string> {
    let url = BackendConfig.Url() + "/Session/returnurl/" + appId;
    const result: string = await BackendApi.get(url)
    return result;
  },

  getDefaultRedirectUrl: async function (): Promise<string> {
    let url = BackendConfig.Url() + "/Session/defaultredirecturl";
    const result: string = await BackendApi.get(url)
    return result;
  }

};
